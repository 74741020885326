.user-setting-section {
  width: 1280px;
  margin: 0 auto;
  
  .contents-row {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    &:nth-of-type(6) {
      margin-bottom: 60px;
    }
    

    .contents-head {
      span{
        display:block;
        width: 198px;
        margin-right: 4px;
        
        &:nth-of-type(1) {
          font-size: 16px;
          font-weight:500;
          color: #333333;

        }
        &:nth-of-type(2) {
          font-size: 12px;
          font-weight:400;
          color: #979797;

        }
      }
    }

    .contents-body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      button {
          font-size: 16px;
          font-weight:400;
          color: #333333;
          padding: 4px 8px;
          border: 1px solid #dcdfe2;
          margin: 2px;
          border-radius: 4px;
          box-sizing: border-box;
          position: relative;

          &.active {
            background: #FEF1E0;
            border: 1px solid #FF662A;
            color: #FF662A;
          }

          &:hover::before {
            content:"";
            position:absolute;
            top:0;
            right:0;
            left:0;
            bottom:0;
            width:100%;
            height:100%;
            background: rgba(0,0,0,.075) ;
          }
      }


      .select.outlined{
        border: 1px solid #dcdfe2;
        color: #333333;
        
        &:first-child {
          margin-right: 4px;
        }
      }


      //커스텀 셀렉트 박스
      .map__order-group {
        display: flex;
        align-items: center;

        .map__order-group-select-box {
            font-size: 16px;
            font-weight:400;
            color: #333333;
            padding: 4px 20px 4px 8px;
            border: 1px solid #dcdfe2;
            margin: 2px;
            border-radius: 4px;
            box-sizing: border-box;
            position: relative;
            background: url("../images/icon/icon_check_down.png") no-repeat 94% 50%;

            &::-ms-expand {
                display: none;
            }
            &:focus {
                border-color: #fff;
                outline: none;
            }

        }
      }

      // SLIDER
      .slider {
        padding: 0 0 0 20px;
        width:400px;
        .primary {
          height: 4px;
          color: #ff662a;
          .MuiSlider-rail {
            height: 4px;
            color: #e7e9ec;
          }
          .MuiSlider-track {
            height: 4px;
          }
          .MuiSlider-mark {
            width: 1px;
            height: 8px;
            top: 25px;
            background-color: #e4e4e4;
          }
          .MuiSlider-markLabel {
            top: 33px;
            color: #747474;
            font-family: 'Noto Sans', 'Open Sans', 'Nanum Gothic', 'Dotum', sans-serif;
            font-size: 0.7em;
            font-weight: 300;
          }
        }
      }
    }

  }
  
  .confirm {
    display:block;
    margin: 0 auto 12px;
    width: 378px;
    height: 56px;
    color: #fff;
    font-size: 16px;
    background: #FF662A;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;

    &:hover::before {
      content:"";
      display:block;
      position:absolute;
      top:0;
      right:0;
      left:0;
      bottom:0;
      width:100%;
      height:100%;
      background: rgba(0,0,0,.075);
    }
  }

  .to-be-continue {
    display:none;
    margin: 0 auto 20px auto;
    color: #333333;
    font-size: 12px;
    padding: 4px 0;
    border-bottom: 1px solid #333333;
    box-sizing: border-box;
    position: relative;

    &.on {
      display:block;
    }

    &:hover {
      color: #979797;
      border-bottom: 1px solid #979797;
    }
  }

  .snackbar-confirm {
    .MuiSnackbarContent-root{
      padding: 10px 20px;
    }

    .MuiSnackbarContent-root{
      background: #0FB553;

      .snackbar-confirm-text {
        margin-right: 10px;
        display: inline-block;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        border-bottom: 1px solid #fff;
      }
    }
    .MuiAlert-standardSuccess {
      background: #0FB553;

      .MuiAlert-icon {
        display: none;
      }
      .MuiAlert-message {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}