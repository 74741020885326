section {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    transition: 0.3s linear;
    @media (max-width: 768px ) {
       width: 50%;
    }
    @media (max-width: 480px) {
      width: 100%;
    }

  }


.loader {
    width : 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    color: #fff;
    animation: rotation 1s linear infinite;
    &:after, &:before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 50%;
      transform: scale(0.5) translate(0 , 0);
      border-radius: 50%;
      background-color: #fff;
      animation: animloader38 1s infinite ease-in-out;
    }
    &:before {
      background-color: #ff662a;
      transform: scale(0.5) translate( -48px , -48px);
    }
  }

  @keyframes animloader38 {
    50% { transform: scale(1) translate(-50% , -50%)}
  }


  @keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }