.brand-detail {
  display: flex;
  margin: 0 auto;
  width: 1280px;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;

  .brand-detail__left-container {
    padding: 20px 20px 54px 0px;
    width: 988px;
    box-sizing: border-box;
  }

  //우측영역
  .brand-detail__right-container {
    background: #f7f8fa;
    width: 292px;
    border-left: 1px solid #dcdfe2;
    border-right: 1px solid #dcdfe2;
    box-sizing: border-box;
    box-shadow: 2px 0 2px #f7f8fa;

    .brand-detail__brand-info {
      background-color: #fff;
      padding: 40px 20px 60px;
      box-sizing: border-box;
      position: relative;
      height: 100%;

      &.dimmed::after {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.75);
      }

      .brand-detail__brand-logo__container {
        width: 48px;
        height: 48px;
        border: 1px solid #dcdfe2;
        border-radius: 6px;
        box-sizing: border-box;
        margin-bottom: 32px;
        display: flex;
        align-items: center;

        .brand-detail__brand-logo {
          width: 100%;
          text-indent: -9999px;
          display: block;
          background-repeat: no-repeat;
        }      }
      

      .brand-detail__barnch-detail {
        span {
          display: block;

          &:nth-of-type(1) {
            font-size: 12px;
            font-weight: 400;
            color: #ff662a;
          }

          &:nth-of-type(2) {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 8px;
          }
        }

        div {
          font-size: 14px;
          font-weight: 400;
          color: #979797;
          margin-bottom: 20px;
        }
      }

      .brand-detail__badge-theme {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 32px;

        .brand-detail__badge-image,
        .brand-detail__theme-image {
          padding: 2px 8px 4px;
          color: #fff;
          background: #ff662a;
          border-radius: 2px;
          box-sizing: border-box;
          text-align: center;
          margin: 0 4px 4px 0px;
        }
      }

      .brand-detail__branch-costs {
        border-top: 1px solid #eeeeee;
        margin-bottom: 32px;
        box-sizing: border-box;

        .brand-detail__start-up-cost,
        .brand-detail__monthly_sales,
        .brand-detail__monthly_sales33,
        .brand-detail__branch-total-cnt {
          padding: 12px 0;
          border-bottom: 1px solid #eeeeee;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;

          span {
            font-size: 12px;
            color: #747474;
          }

          div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            span {
              display: block;

              &:nth-of-type(1) {
                font-size: 14px;
                font-weight: 500;
                color: #ff662a;
              }

              &:nth-of-type(2) {
                font-size: 12px;
                color: #b3b3b3;
              }
            }
          }
        }
      }

      .brand-detail__button-check-group {
        display: flex;
        margin-bottom: 12px;
      }

      .brand-detail__button--brand-intro {
        margin-bottom: 12px;
      }

      .brand-detail__button {
        background-color: #ff662a;
        height: 48px;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        display: flex;
        width: 100%;
        padding: 16px;
        margin: 0 0 0 0;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;

        &:hover::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100;
          height: 100;
          background: rgba(0, 0, 0, 0.075);
        }

        &.brand-detail__button--sales {
          background: url('../images/icon/icon_like_default.svg');
          background-repeat: no-repeat;
          background-size: 18px 18px;
          background-color: #fff;
          background-position: 19% 55%;
          border-radius: 6px 0 0 6px;
          border-left: 0px solid #979797;
          border: 1px solid #b1bac3;
          color: #747474;
          padding: 0 0 0 26px;
          box-sizing: border-box;
          // width: 86px;
          height: 48px;
          margin-right: -1px;
          font-weight: 400;

          &.on {
            background-image: url('../images/icon/icon_like_select.svg');
          }
        }
        &.brand-detail__button--compare {
          background: url('../images/icon/icon_checkbox_small_default.svg');
          background-repeat: no-repeat;
          background-size: 18px 18px;
          background-color: #fff;
          background-position: 27% 52%;
          border-radius: 0;
          border-left: 0px solid #979797;
          border: 1px solid #b1bac3;
          color: #747474;
          padding: 0 0 0 21px;
          box-sizing: border-box;
          // width: 85px;
          height: 48px;
          font-weight: 400;
          //----위 2개 주석 아래 1줄 추가 21.11.06 james
          border-radius: 0 6px 6px 0;

          &.on {
            display: inline-block;
            background: url('../images/icon/icon_check_select.png');
            background-repeat: no-repeat;
            background-size: 18px 18px;
            // width: 85px;
            height: 48px;
            background-color: #fff;
            background-position: 27% 52%;
          }
        }

        &.brand-detail__button--share {
          background: url('../images/icon/icon_share.png');
          background-repeat: no-repeat;
          background-size: 18px 18px;
          background-color: #fff;
          background-position: 27% 50%;
          border-radius: 0 6px 6px 0;
          border: 1px solid #b1bac3;
          color: #747474;
          padding: 0 0 0 21px;
          box-sizing: border-box;
          width: 85px;
          height: 48px;
          font-weight: 400;
          margin-left: -3px;
        }

        &.brand-detail__button--brand-intro {
          background-color: #fff;
          border: 2px solid #ff662a;
          color: #ff662a;
        }

        &.brand-detail__button--franchise-application {
          margin-bottom: 12px;
        }
      }
    }
  }
}

// 가맹점 모집 중
.brand-detail__recruit {
  margin-bottom: 116px;
  display: none;

  &.active {
    display: block;

    .brand-detail__recruit-title-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      .brand-detail__recruit-title-group-left {
        span:nth-of-type(1) {
          color: #333333;
          font-size: 18px;
          font-weight: 700;
          margin-right: 8px;
          display: inline-block;
        }
        span:nth-of-type(2) {
          color: #ff662a;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .brand-detail__recruit-title-group-right {
        background: #fff;

        .brand-detail__prev-arrow {
          background: url('../images/icon/rectangle_small_left.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          text-indent: -9999px;
          box-sizing: border-box;
          width: 32px;
          height: 32px;
          margin-right: -1px;
          position: relative;

          &:hover::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100;
            height: 100;
            background: rgba(0, 0, 0, 0.075);
            border-radius: 7px 0 0 7px;
          }
        }

        .brand-detail__next-arrow {
          background: url('../images/icon/rectangle_small_right.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          text-indent: -9999px;
          box-sizing: border-box;
          width: 32px;
          height: 32px;
          position: relative;

          &:hover::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100;
            height: 100;
            background: rgba(0, 0, 0, 0.075);
            border-radius: 0 7px 7px 0;
          }
        }
      }

      .brand-detail__prev-arrow {
      }
      .brand-detail__next-arrow {
      }
    }

    .brand-detail__recruit-card-group {
      display: flex !important; //slick에서 inline-block 지정함.
      // justify-content: space-between;

      .brand-detail__recruit-content-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px;
        box-sizing: border-box;
        border: 1px solid #dcdfe2;
        box-shadow: 0px 0px 2px #dcdfe2;
        border-radius: 6px;
        margin-right: 24px;

        &:nth-of-type(3n + 3) {
          margin-right: 0;
        }

        .brand-detail__recruit-content-area {
          color: #333333;
          font-size: 18px;
          font-weight: 500;
          box-sizing: border-box;
          margin-bottom: 8px;
        }
        .brand-detail__recruit-content-event-group {
          display: flex;
          margin-bottom: 40px;
          align-items: center;

          .brand-detail__recruit-content-event-badge {
            width: 47.45px;
            background-color: #ff662a;
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            display: inline-block;
            border-radius: 5px;
            padding: 1px 8px 1px 8px;
            box-sizing: border-box;
            margin-right: 8px;
          }
          .brand-detail__recruit-content-event-text {
            width: 177px;
            color: #333333;
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
          }
        }

        .brand-detail__recruit-content-btn-group {
          display: flex;
          justify-content: space-between;

          .brand-detail__recruit-content-btn-map {
            background-color: #fff;
            height: 48px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            display: inline-flex;
            width: 100%;
            padding: 14px 16px;
            margin-right: 8px;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            box-sizing: border-box;
            border: 1px solid #dcdfe2;
            border-radius: 4px;
            position: relative;

            &:hover::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.075);
            }
            &.inactive {
              background-color: #dcdfe2;
            }
          }
          .brand-detail__recruit-content-btn-inquire {
            background-color: #fff;
            height: 48px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            display: inline-flex;
            width: 100%;
            padding: 14px 16px;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            box-sizing: border-box;
            border: 1px solid #dcdfe2;
            border-radius: 4px;
            position: relative;

            &:hover::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.075);
            }
            &.inactive {
              background-color: #dcdfe2;
            }
          }
        }
      }
    }
  }
}

//가맹점 증감추이
.brand-detail__franchise-increase-trend {
  margin-top: 40px;

  .brand-detail__title {
    display: block;
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .brand-detail__sub-title {
    display: block;
    color: #979797;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  //차트
  .brand-detail__head-quater-chart {
    //echart 구역
    .brand-detail__head-quater-chart-content {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  //재무제표
  .brand-detail__financial-statements {
    margin-bottom: 20px;

    .brand-detail__title {
      display: block;
      color: #333333;
      font-size: 16px;
      font-weight: 700;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    div {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      &:nth-of-type(1) {
        background: #f7f8fa;
        border-top: 1px solid #b3b3b3;
        border-bottom: 1px solid #dcdfe2;
        color: #747474;
        text-align: center;
      }

      &:not(:first-child) {
        color: #333333;
        text-align: center;
        border-bottom: 1px solid #b3b3b3;
      }

      span {
        display: block;
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        padding: 19px 0px 19px 16px;
        box-sizing: border-box;
      }
    }
  }
}

//가맹점 계약현황
.brand-detail__franchise-contract-status {
  margin-top: 40px;

  .brand-detail__title {
    display: block;
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .brand-detail__sub-title {
    display: block;
    color: #979797;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  //차트
  .brand-detail__head-quater-chart {
    //echart 구역
    .brand-detail__head-quater-chart-content {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  //재무제표
  .brand-detail__financial-statements {
    margin-bottom: 20px;

    .brand-detail__title {
      display: block;
      color: #333333;
      font-size: 16px;
      font-weight: 700;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    div {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      &:nth-of-type(1) {
        background: #f7f8fa;
        border-top: 1px solid #b3b3b3;
        border-bottom: 1px solid #dcdfe2;
        color: #747474;
        text-align: center;
      }

      &:not(:first-child) {
        color: #333333;
        text-align: center;
        border-bottom: 1px solid #b3b3b3;
      }

      span {
        display: block;
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        padding: 19px 0px 19px 16px;
        box-sizing: border-box;
      }
    }
  }
}

//가맹점 월평균 매출
.brand-detail__franchise-avg-monthly-sales {
  margin-top: 40px;

  .brand-detail__title {
    display: block;
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .brand-detail__sub-title {
    display: block;
    color: #979797;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  //차트
  .brand-detail__head-quater-chart {
    //echart 구역
    .brand-detail__head-quater-chart-content {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  //재무제표
  .brand-detail__financial-statements {
    margin-bottom: 20px;

    .brand-detail__title {
      display: block;
      color: #333333;
      font-size: 16px;
      font-weight: 700;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    div {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      &:nth-of-type(1) {
        background: #f7f8fa;
        border-top: 1px solid #b3b3b3;
        border-bottom: 1px solid #dcdfe2;
        color: #747474;
        text-align: center;
      }

      &:not(:first-child) {
        color: #333333;
        text-align: center;
        border-bottom: 1px solid #b3b3b3;
      }

      span {
        display: block;
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        padding: 19px 0px 19px 16px;
        box-sizing: border-box;
      }
    }
  }
}

// 가맹점 창업비용, 본사 임직원 수
.brand-detail__employees-head-quater,
.brand-detail__start-up-costs {
  margin-top: 40px;

  .brand-detail__title {
    display: block;
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .brand-detail__sub-title {
    display: block;
    color: #979797;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  .brand-detail__base-area {
    display: block;
    color: #333333;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .brand-detail__table-group {
    border-top: 1px solid #b3b3b3;
    box-sizing: border-box;

    div {
      border-bottom: 1px solid #dcdfe2;
      display: flex;
      box-sizing: border-box;
      align-items: center;

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        padding: 19px 0px 19px 16px;
        box-sizing: border-box;

        &:nth-of-type(1) {
          color: #747474;
          background: #f7f8fa;
          width: 204px;
        }

        &:nth-of-type(2) {
          color: #333333;
        }
      }
    }
  }
  .brand-detail__table_total {
    display: flex;
    border-bottom: 1px solid #b3b3b3;
    box-sizing: border-box;
    background: #f0f1f4;
    margin-bottom: 20px;

    span {
      display: block;
      font-size: 16px;
      font-weight: 500;
      padding: 19px 0px 19px 16px;
      box-sizing: border-box;

      &:nth-of-type(1) {
        width: 204px;
      }
    }
  }
}

// 가맹점 운영비용, 본사 기업정보
.brand-detail__head-quater,
.brand-detail__operation-costs {
  margin-top: 40px;

  .brand-detail__title {
    display: block;
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .brand-detail__sub-title {
    display: block;
    color: #979797;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  .brand-detail__base-area {
    display: block;
    color: #333333;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .brand-detail__table-group {
    border-top: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
    margin-bottom: 20px;
    box-sizing: border-box;

    div {
      display: flex;
      box-sizing: border-box;
      &:not(:last-child) {
        border-bottom: 1px solid #dcdfe2;
      }

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        padding: 19px 0px 19px 16px;
        box-sizing: border-box;

        &:nth-of-type(1) {
          color: #747474;
          background: #f7f8fa;
          width: 204px;
        }

        &:nth-of-type(2) {
          color: #333333;
        }
      }
    }
  }
}

// 가맹점 광고/판촉 내역
.brand-detail__franchise-ad-history {
  margin-top: 40px;

  .brand-detail__title {
    display: block;
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .brand-detail__sub-title {
    display: block;
    color: #979797;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  .brand-detail__base-area {
    display: block;
    color: #333333;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .brand-detail__table-group {
    border-top: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
    margin: 0 0 20px 0;
    box-sizing: border-box;

    div {
      display: flex;
      align-items: center;

      &:first-child {
        border-bottom: 1px solid #dcdfe2;
        box-sizing: border-box;
      }

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        padding: 19px 0px 19px 16px;
        box-sizing: border-box;

        &:nth-of-type(1) {
          color: #747474;
          background: #f7f8fa;
          width: 204px;
        }

        &:nth-of-type(2) {
          color: #333333;
        }
      }
    }
  }
  .brand-detail__table_total {
    display: flex;
    border-bottom: 1px solid #b3b3b3;
    box-sizing: border-box;
    background: #f0f1f4;
    margin-bottom: 20px;

    span {
      display: block;
      font-size: 16px;
      font-weight: 500;
      padding: 19px 0px 19px 16px;
      box-sizing: border-box;

      &:nth-of-type(1) {
        width: 204px;
      }
    }
  }
}

// 최근 3년간 법 위반 사실
.brand-detail__violation {
  margin-top: 40px;

  .brand-detail__title {
    display: block;
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .brand-detail__sub-title {
    display: block;
    color: #979797;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  .brand-detail__table-group {
    border-top: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
    margin-bottom: 20px;
    box-sizing: border-box;

    div {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      &:nth-of-type(1) {
        background: #f7f8fa;
        border-bottom: 1px solid #dcdfe2;
        color: #747474;
      }

      &:nth-of-type(2) {
        color: #333333;
      }

      span {
        display: block;
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        padding: 19px 0px 19px 16px;
        box-sizing: border-box;
      }
    }

    .brand-detail__violation-detail {
      padding: 16px;
      font-size: 14px;
      font-weight: 400;
      border-top: 1px solid #dcdfe2;
    }
  }
}

//본사 매출정보
.brand-detail__head-quater-sales {
  margin-top: 40px;

  .brand-detail__title {
    display: block;
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .brand-detail__sub-title {
    display: block;
    color: #979797;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  //차트
  .brand-detail__head-quater-chart {
    .brand-detail__head-quater-chart-button-group {
      margin: 20px 0;

      button {
        color: #b3b3b3;
        font-size: 14px;
        font-weight: 400;
        padding: 7px 12px;
        box-sizing: border-box;
        border: 1px solid #dcdfe2;
        border-radius: 2px;
        position: relative;

        &:not(:last-child) {
          margin-right: 4px;
        }

        &.active {
          color: #fff;
          font-weight: 700;
          background: #ff662a;
          border: 1px solid #ff662a;
          border-radius: 2px;
        }

        &:hover::after {
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background: rgba(0, 0, 0, 0.075);
        }
      }
    }

    //echart 구역
    .brand-detail__head-quater-chart-content {
      margin-bottom: 20px;
    }
  }

  //재무제표
  .brand-detail__financial-statements {
    margin-bottom: 20px;

    .brand-detail__title {
      display: block;
      color: #333333;
      font-size: 16px;
      font-weight: 700;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    div {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      &:nth-of-type(1) {
        background: #f7f8fa;
        border-top: 1px solid #b3b3b3;
        border-bottom: 1px solid #dcdfe2;
        color: #747474;
        text-align: center;
      }

      &:not(:first-child) {
        color: #333333;
        text-align: center;
        border-bottom: 1px solid #b3b3b3;
      }

      span {
        display: block;
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        padding: 19px 0px 19px 16px;
        box-sizing: border-box;
      }
    }
  }
}

//이런 프랜차이즈 어때요 상단
.brand-detail__what-franchise-like-title-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .brand-detail__what-franchise-like-title-group-left {
    span:nth-of-type(1) {
      color: #333333;
      font-size: 18px;
      font-weight: 700;
      margin-right: 8px;
      display: inline-block;
    }
    span:nth-of-type(2) {
      color: #ff662a;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .brand-detail__what-franchise-like-title-group-right {
    background: #fff;

    .brand-detail__prev-arrow {
      background: url('../images/icon/rectangle_small_left.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      text-indent: -9999px;
      width: 32px;
      height: 32px;
      margin-right: -1px;
      position: relative;

      &:hover::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100;
        height: 100;
        background: rgba(0, 0, 0, 0.075);
        border-radius: 7px 0 0 7px;
      }
    }

    .brand-detail__next-arrow {
      background: url('../images/icon/rectangle_small_right.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      text-indent: -9999px;
      width: 32px;
      height: 32px;
      position: relative;

      &:hover::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100;
        height: 100;
        background: rgba(0, 0, 0, 0.075);
        border-radius: 0 7px 7px 0;
      }
    }
  }
}

.brand-detail__what-franchise-like-body-group {
  .slick-slider {
    .slick-slide {
      > div {
        display: flex ;
        justify-content: flex-start ;
        .item:not(:last-child) {
          margin-right: 40px;
        }
      } 
    }    
  }
  margin-bottom: 40px;
}

//최근 본 브랜드
.brand-detail__seen-recently-title-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;

  .item:not(:last-child) {
    margin-right:40px;
  }

  .brand-detail__seen-recently-title-group-left {
    span:nth-of-type(1) {
      color: #333333;
      font-size: 18px;
      font-weight: 700;
      margin-right: 8px;
      display: inline-block;
    }
    span:nth-of-type(2) {
      color: #ff662a;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.brand-detail__seen-recently-body-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

//머티리얼 ui 초기화
[class^='MuiBox-root MuiBox-root-'],
[class^='MuiTypography-root MuiTypography-body'] {
  padding: 0 !important;
  margin: 0 !important;
}

[class^='PrivateTabIndicator-colorPrimary'] {
  background-color: #fff;
}

.MuiTypography-root {
  &.MuiTypography-body1 {
    font-family: 'Noto Sans', 'Open Sans', 'Nanum Gothic', 'Dotum', sans-serif;
  }
}


//머티리얼 탭 UI 수정
.MuiTabs-root {
  .MuiTabs-scroller {
    .MuiTabs-scrollButtons.Mui-disabled {
      opacity: 0.3;
    }

    .PrivateTabIndicator-root-1 {
      &.PrivateTabIndicator-colorPrimary-2 {
        background-color: #fff;
        border-bottom: 4px solid #b3b3b3;
        &.MuiTabs-indicator {
          border-bottom: 4px solid #ff662a;
        }
      }
    }

    .MuiTabs-flexContainer + span {
      background-color: #ff662a;
    }

    .MuiTabs-flexContainer {
      .MuiButtonBase-root {
        &.MuiTab-root {
          &.MuiTab-textColorInherit {
            font-size: 18px;
            font-weight: 400;
            color: #b3b3b3;

            &.Mui-selected {
              position: relative;
              .MuiTab-wrapper {
                color: #ff662a;
                font-weight: 700;
              }
              .MuiTouchRipple-root::before {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}

//브랜드 그룹의 자식 브랜드
.brand-detail__brans-of-head-quater {
  margin-bottom: 40px;

  .brand-detail__brans-of-head-quater-title-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .brand-detail__brans-of-head-quater-title-group-left {

      span:nth-of-type(1) {
        color: #333333;
        font-size: 18px;
        font-weight: 700;
        display: block;
      }
      span:nth-of-type(2) {
        color: #ff662a;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .brand-detail__brans-of-head-quater-title-group-right {
      background: #fff;

      .brand-detail__prev-arrow {
        background: url('../images/icon/icon_arrow_left_default.png');
        background-size: fit;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        text-indent: -9999px;
        border: 1px solid #dcdfe2;
        border-radius: 4px 0 0 4px;
        box-sizing: border-box;
        width: 32px;
        height: 32px;
        margin-right: -1px;
        position: relative;

        &:hover::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100;
          height: 100;
          background: rgba(0, 0, 0, 0.075);
          border-radius: 7px 0 0 7px;
        }
      }

      .brand-detail__next-arrow {
        background: url('../images/icon/icon_arrow_right_default.png');
        background-size: fit;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        text-indent: -9999px;
        border: 1px solid #dcdfe2;
        border-radius: 0 4px 4px 0;
        box-sizing: border-box;
        width: 32px;
        height: 32px;
        position: relative;

        &:hover::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100;
          height: 100;
          background: rgba(0, 0, 0, 0.075);
          border-radius: 0 7px 7px 0;
        }
      }
    }
  }

  .brand-detail__brans-of-head-quater-brands {
    // display: flex;

    .slick-track  {
      margin-left: 0 !important;
    }

    :not(:last-child) {
      margin-right: 0px;
    }

    .brand-detail__brans-of-head-quater-brand-group {
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid #b3b3b3;
      border-radius: 4px;
      width: 276px;
      height: 76px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      &:hover {
        border: 1px solid #ff662a;
      }

      &:hover i {
        border: 1px dashed #ff662a;
        background: url('../images/icon/icon_more_select.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }

      div {
        display: flex;
        align-items: center;

        .brand-detail__brans-of-head-quater-brand-group-img-group {
          display: flex;
          align-items: center;
          width: 36px;
          height: 36px;
          margin-right: 15px;
          box-sizing: border-box;
          border-radius: 4px;
          border: 1px solid #dcdfe2;

          img {
            width: 100%;
          }
        }
        
        span {
          color: #333333;
          font-size: 14px;
          font-weight: 400;
        }
      }

      i {
        width: 18px;
        height: 18px;
        background: url('../images/icon/icon_more_default.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        border: 1px dashed #b3b3b3;
        box-sizing: border-box;
      }
    }
  }
}

//담당자 코멘트
.brand-detail__representative-comment {
  display: flex;
  padding: 20px;
  background: #fdf7ef;
  border-radius: 4px;
  border: 1px solid #faf1e6;
  box-sizing: border-box;
  margin-bottom: 60px;

  .brand-detail__representative-comment-img-group {
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe2;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    margin-right: 24px;

    img {
      width: 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
    }
  }
  

  div {
    .comment-area {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      word-break: break-word;
    }
    div {
      display: flex;
      align-items: center;
      margin: 12px 0;
      line-height: 1.4;

      span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #333333;

        &:nth-of-type(1) {
          margin-right: 8px;
        }
        &:nth-of-type(2) {
          font-size: 12px;
          color: #b3b3b3;
        }
      }
    }
  }
}
