@import 'layout';

// MODAL STYLE
.modal {
  border-radius: 6px;
  background-color: #fff;
  .modal-header {
    position: relative;
    padding: 8px 15px;
    background-color: #ff662a;
    color: #fff;
    font-size: 0.9em;

    .modal-close {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 40px;
      @include ic-modal-close;
    }
  }

  .modal-content {
    padding: 16px;
  }

  .modal-footer {
    padding: 0 16px 16px;

    &.theme-and-badge {
      padding: 14px;
      border-top: 1px solid #f5f5f5;
    }
    .btn-modal-ok {
      min-width: 60px;
      padding: 3px 5px;
    }
  }

  &.cate-add {
    .modal-content {
      .select {
        margin-left: 5px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &.selector-container {
    &.added {
      border: 1px solid #ff662a;
    }

    .checkbox-group {
      min-width: 112px;
      margin: 8px 0 4px 0;

      .select-all {
        border-bottom: 1px solid #f5f5f5;
        .checkbox.custom {
          padding: 10px;
          color: #333333;
          font-size: 14px;
          line-height: 1.8;
          font-weight: 400;
          margin-bottom: 4px;

          .Mui-checked {
            color: #ff662a;
          }

          .Mui-checked ~ .MuiFormControlLabel-label {
            color: #ff662a;
          }
        }
      }
      .select-list {
        margin-top: 4px;

        .dropdown.checkbox.custom {
          padding: 8px 10px;
          font-size: 14px;
          line-height: 1.8;
          font-weight: 400;

          .Mui-checked {
            color: #ff662a;
          }

          .Mui-checked ~ .MuiFormControlLabel-label {
            color: #ff662a;
          }
        }
      }
    }
  }

  &.range-container {
    width: 300px;
    .cost-select {
    }
  }
}

// SLIDER
.slider {
  padding: 0 10px 20px;
  .primary {
    height: 4px;
    color: #ff662a;
    .MuiSlider-rail {
      height: 4px;
      color: #e7e9ec;
    }
    .MuiSlider-track {
      height: 4px;
    }
    .MuiSlider-mark {
      width: 1px;
      height: 8px;
      top: 25px;
      background-color: #e4e4e4;
    }
    .MuiSlider-markLabel {
      top: 33px;
      color: #747474;
      font-family: 'Noto Sans', 'Open Sans', 'Nanum Gothic', 'Dotum', sans-serif;
      font-size: 0.7em;
      font-weight: 300;
    }
  }
}

// CHECKBOX STYLE
.checkbox {
  &.custom {
    display: flex;
    margin: 0;
    padding: 10px 15px;
    .MuiCheckbox-root {
      padding: 0 7px 0 0;
    }
    .MuiFormControlLabel-label {
      font-family: 'Noto Sans', 'Open Sans', 'Nanum Gothic', 'Dotum', sans-serif;
    }

    &:hover {
      background-color: #f0f1f4;
    }
  }
}

// SELECTBOX STYLE
.select {
  height: 40px;
  &.outlined {
    background-image: url(../images/icon/icon_arrow_down.svg) !important;
    padding: 0 30px 0 10px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    color: #333333;
    @include ic-select-outlined-arrow;
  }

  .MuiButton-label {
    font-family: 'Noto Sans', 'Open Sans', 'Nanum Gothic', 'Dotum', sans-serif;
    font-weight: 400;
  }
}

// BUTTON STYLE
.btn-reset {
  padding-left: 15px;
  text-indent: 15px;
  @include ic-initial-black;
}
.btn-close-search {
  color: #979797;
  padding-left: 15px;
  @include ic-close;
}

// ALIGN STYLE
.alignL {
  text-align: left;
}
.alignC {
  text-align: center;
}
.alignR {
  text-align: right;
}
.align-between {
  display: flex;
  justify-content: space-between;
}

// BUTTON STYLE
.btn {
  display: inline-block;
}
.btn-outlined-add {  
  background-image: url(../images/icon/icon_plus.svg) !important;

  &.MuiButton-outlined {
    padding: 9px 28px 9px 10px;
    border: 1px solid #e5e5e5;
    vertical-align: baseline;
    line-height: normal;
  }
  .MuiButton-label {
    font-family: 'Noto Sans', 'Open Sans', 'Nanum Gothic', 'Dotum', sans-serif;
    font-weight: 400;
  }
  @include ic-add;
}
.btn-primary {
  padding: 13px 18px;
  border-radius: 4px;
  background-color: #ff662a;
  color: #fff;
  font-size: 1em;
  &.size-l {
    min-width: 200px;
  }
}
.btn-unitA {
  display: block;
  width: 100%;
  padding: 0 10px;
  line-height: 100px;
  text-align: left;
  &:hover {
    background-color: #eee;
  }
}
.btn-unitB {
  background-color: #eee;
}
.btn-close {
  position: absolute;
  right: -45px;
  top: 50%;
  width: 100px;
  text-align: right;
  background-color: #fff;
  @include elem-pos(0, -50%);
}
.btn-close-detail {
  position: absolute;
  right: -45px;
  top: 20px;
  width: 100px;
  text-align: right;
  background-color: #fff;
  @include elem-pos(0, -50%);
}
.btn-reload {
  position: absolute;
  left: 50%;
  bottom: 10px;
  @include elem-pos(195px, 0);

  .btn-color {
    color: white;
    background-color: #ff662a;
    &:hover {
      color: white;
      background-color: #ee552a;
    }
  }
}

.select {
  font-size: 0.9em;
}

// CHECKBOX STYLE
.checkbox {
  display: inline-block;
  input[type='checkbox'] {
    display: none;

    & + i {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #b3b3b3;
      background-image: url(../images/icon/icon_checkbox_large_default.png);
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    &:checked + i {
      border-color: #ff662a;
      background-image: url(../images/icon/icon_checkbox_large_select.png);
    }
  }

  &.check-outlined {
    input[type='checkbox'] {
      display: none;
      & + span {
        @include transition(all 0.1s ease-in);
        display: inline-block;
        padding: 8px 10px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        color: #333333;
        font-size: 0.9em;
      }
      &:checked + span {
        background-color: #fef1e0;
        border-color: #ff662a;
        color: #ff662a;
        font-weight: 400;
      }
    }
  }

  &.deletable {
    position: relative;

    input[type='checkbox'] {
      display: none;
      & + span {
        @include transition(all 0.1s ease-in);
        display: inline-block;
        padding: 8px 30px 8px 10px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        color: #333333;
        font-size: 0.9em;
      }
      &:checked + span {
        background-color: #fef1e0;
        border-color: #ff662a;
        color: #ff662a;
        font-weight: 400;
      }
    }

    .deletable-btn {
      position: absolute;
      top: 16px;
      right: 10px;
      background: url('../images/icon/icon_close_500.svg');
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0.4;
      width: 10px;
      height: 10px;
    }
  }

  &.check-primary {
    input[type='checkbox'] {
      & + i {
        width: 18px;
        height: 18px;
        border: 0px solid #b1bac3;
        border-radius: 0px;
        background-image: url(../images/icon/icon_checkbox_small_default.svg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &:checked + i {
        background-color: #ff662a;
        border-color: #ff662a;
        border: 2px solid #ff662a;
        border-radius: 6px;
        background-image: url(../images/icon/icon_checkbox_small_select.png);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    span {
      padding-left: 5px;
      color: #979797;
    }
  }

  &.check-like {
    input[type='checkbox'] {
      & + i {
        width: 18px;
        height: 18px;
        border: 0;
        background-image: url(../images/icon/icon_like_default@2x.png);
      }
      &:checked + i {
        border: 0;
        background-image: url(../images/icon/icon_like_select@2x.png);
      }
    }
  }

  &.check-show-hide {
    input[type='checkbox'] {
      & + i {
        width: 18px;
        height: 18px;
        border: 0;
        background-image: url('../images/icon/icon_eye_default.png');
      }
      &:checked + i {
        border: 0;
        background-image: url('../images/icon/icon_eye_default.png');
        filter: sepia(70%) brightness(70%);
      }
    }
  }
}

// GROUP BOX
.bottom-btn-container {
  padding: 40px 0 100px;
}

// FLEX STYLE
.row {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;

  &.flex-wrap {
    flex-wrap: wrap;
  }
}

// TITLE STYLE
.title-group {
  position: relative;
  &.primary {
    margin-bottom: 60px;
    line-height: 2em;
    h2 {
      font-size: 1.2em;
      font-weight: 500;
    }
    h3 {
      color: #979797;
    }
    > .all {
      position: absolute;
      right: 0;
      top: 0;
      color: #979797;
      font-size: 1em;
      padding-right: 15px;
      cursor: pointer;
      // @include bg-set('../images/icon/icon_plus_2.png', 100% 50%);
    }
  }
}

// ITEM CONTAINER STYLE
.list-container {
  display: none;
  @include clfix;

  .item {
    float: left;
    margin: 0 0 60px 58px;

    &:nth-of-type(4n + 1) {
      margin-left: 0px;
    }
  }

  &.active {
    display: block;
  }
}

// ITEM STYLE
.keyword-item {
  span {
    margin-right: 4px;
    color: #ff662a;
    font-size: 0.9em;
    font-weight: 500;
  }
  button {
    width: 12px;
    height: 12px;
    @include ic-delete;
  }
}

.unit-type-normal {
  display: block;
  max-width: 276px;
  min-height: 380px;
  
  > .thumb {
    display: block;
    overflow: hidden;
    position: relative;
    width: 276px;
    height: 183px;
    border-radius: 10px;
    background-color: #ddd;
    box-sizing: border-box;

    &:hover {
      img {
        width: 120%;
      }
    }

    .badge {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      left: 10px;
      top: 10px;

      span {
        display: inline-block;
        margin: 0 3px 3px 0;
        padding: 4px 10px;
        background-color: rgba(255, 102, 42, 0.8);
        border-radius: 3px;
        color: #fff;
        font-size: 0.8em;
        letter-spacing: normal;
      }
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      max-width: none;
      @include elem-pos(-50%, -50%);
      @include transition(width 2s ease);
    }
  }

  .brand {
    position: relative;
    margin: 10px 0 15px;
    display: flex;

    .thumb {
      overflow: hidden;
      width: 48px;
      height: 48px;
      border-radius: 6px;
      background-color: #ddd;
      margin-right: 6px;
      display: flex;
      align-items: center;;
      img {
        width: 100%;
      }
    }
    .info-group {
      flex: 1;

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cate {
          color: #ff662a;
          font-size: 0.8em;
          font-weight: 300;
        }
        .util {
          display: flex;
          align-items: center;

          label {
            margin-left: 10px;
            display: flex;
            &:first-child {
              margin-left: 0;
              display: flex;
            }
          }

          .comparison {
            display: inline-block;
            width: 18px;
            height: 18px;
            
            background-image: url(../images/icon/icon_checkbox_small_default.svg);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 4px;
            &.on {
              display: inline-block;
              background-color: #ff662a;
              border-color: #ff662a;
              border: 2px solid #ff662a;
              border-radius: 6px;
              background-image: url(../images/icon/icon_checkbox_small_select.png);
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
          .comparison + span {
            color: #979797;
            font-size: 12px;
            font-weight: 400;
          }

          .favorite {
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 0;
            background-image: url(../images/icon/icon_like_default.svg);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;

            &.on {
              display: inline-block;
              border: 0;
              background-image: url(../images/icon/icon_like_select.svg);
            }
          }
        }
      }
      .name {
        color: #333333;
        font-size: 1.15em;
      }
    }
  }

  .detail {
    line-height: 1.4em;
    > .p {
      font-size: 0.8em;
      > span {
        margin-right: 5px;
        font-weight: 300;
      }
      > strong {
        margin-right: 5px;
        font-weight: 400;
      }
      > .sub {
        margin: 0;
        color: #b3b3b3;
        font-size: 12px;
      }
    }
  }
}

// DESCRIPTION STYLE
.desc-primary {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 40px;
  &:first-child {
    margin-left: 0;
  }
  dt {
    min-width: 75px;
    font-size: 0.8em;
  }
  //값 선택 되었을 때, 색상 처리
  .added {
    flex-wrap: wrap;
    flex-direction: row;
    .select.outlined {
      border: 1px solid #ff662a;
      color: #ff662a;
      background: url('../images/icon/icon_select_down.png') #fef1e0;
      background-repeat: no-repeat;
      background-position: calc(100% - 5px) 50%;
    }
  }
}

// SLIDER STYLE
.slick-slider {
  .slick-slide {
    > div {
      height: 100%;
    }
  }
  .slick-dots {
    li {
      margin: 0 4px;
      padding: 0;
      width: auto;
      height: auto;
      &:first-child {
        margin-left: 0;
      }
      button {
        width: 8px;
        height: 8px;
        padding: 0;
        background-color: #dcdfe2;
        border-radius: 50%;
        &::before {
          content: none;
        }
      }

      &.slick-active {
        button {
          background-color: #ff662a;
        }
      }
    }
  }
}
.naver-auth-modal {
  padding: 40px;
  overflow: auto;

  .naver_auth_view {
    background: #eeeeee;
    width: 500px;
    margin: auto;
  }
}
