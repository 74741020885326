.admin-login__write_view {
  position: relative;
  top: 129px;
  left:0;
  width: 378px;
  margin: 0 auto;

  .admin-login-title {
    font-size: 30px;
    font-weight: 700;
    color: #333333;
    text-align: center;
    margin-bottom:30px;
  }

  .admin-login__input {

    .admin-login__email {
      position: relative;
      margin: 0 0 36px 0;

      .admin-login__email-input-box {
        display: block;
        width: 100%;
        height: 56px;
        padding: 10px 7px;
        border: 1px solid #747474;
        border-radius: 4px;
        outline:none;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        box-sizing: border-box;

        &:focus{
          color: #333333;
          border: 2px solid #333333;
          ;
        }

        &:focus+.admin-login__email-title {
          color: #333333;
        }  
      }

      .admin-login__email-title {
        font-size: 12px;
        font-weight: 400;
        color: #747474;
        position: absolute;
        top: -10px;
        left: 6px;
        padding: 2px;
        background: #fff;
      }
    }

    .admin-login__password {
      position: relative;
      margin: 0 0 15px 0;

      .admin-login__password-input-box {
        display: block;
        width: 100%;
        height: 56px;
        padding: 10px 7px;
        border: 1px solid #747474;
        border-radius: 4px;
        outline:none;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        box-sizing: border-box;

        &:focus{
          color: #333333;
          border: 2px solid #333333;
          ;
        }

        &:focus+.admin-login__password-title {
          color: #333333;
        }       
      }

      .admin-login__password-title {
        font-size: 12px;
        font-weight: 400;
        color: #747474;
        position: absolute;
        top: -10px;
        left: 6px;
        padding: 2px;
        background: #fff;
      }
    }

    
  }
  
  .admin-login__btn {
    background-color: #FF662A;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    display: flex;
    width: 100%;
    padding: 16px;
    margin: 32px 0 32px 0;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;

    &:hover::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .075);
    }
    &.inactive {
      background-color: #DCDFE2;
    }
  }

  .admin-login__find-password,
  .admin-login__user-sign-up,
  .admin-login__corp-sign-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 21px;

    span {
      font-size: 12px;
      font-weight: 400;
      color: #979797;
      &:last-child {
        cursor: pointer;
      }
    }

    button {
      font-size: 12px;
      color: #000;
      line-height: 1.4;
      text-decoration:underline;

      &:hover {
        color:rgba(0, 0, 0, .75);
      }
    }

    
  }
}