
//Login message
.login-message__container {
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 378px;
  background: #fff;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  .login-message__title-group {
    display:flex;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    padding: 20px 20px;
    border-bottom: 1px solid #B3B3B3;

    .login-message__title {
      color: #FF662A;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
    }
    .login-message__close-btn {
      background: url('../images/icon/icon_close.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width:18px;
      height:18px;
      display:block;
      text-indent: -9999px;
      cursor: pointer;
    }
  }

  .login-message__content-group {
    display:flex;
    width: 100%;
    flex-direction: column;
    padding: 30px 20px;
    
    .login-message__content-content {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 34px;
    }

    .login-message__content-btn {
      background-color: #FF662A;
      height: 56px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      display: flex;
      width: 100%;
      padding: 16px;
      margin-bottom: 10px 0;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      cursor: pointer;

      &:hover::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .075);
      }
      &.inactive {
        background-color: #DCDFE2;
      }
    }
  }
}