.search-brand__container {
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 378px;
  background: #fff;
  border-radius: 4px;

  &:focus {
    outline: none;
  }
  
  .search-brand__title-group {
    display:flex;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    padding: 20px 20px;
    border-bottom: 1px solid #B3B3B3;

    .search-brand__title {
      color: #FF662A;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
    }
    .search-brand__close-btn {
      background: url('../images/icon/icon_close.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width:18px;
      height:18px;
      display:block;
      text-indent: -9999px;
      cursor: pointer;
    }
  }

  .search-brand__content-group {
    display:flex;
    width: 100%;
    flex-direction: column;

    .search-brand__content-group-inner {
      display:flex;
      width: 100%;
      flex-direction: column;
      padding: 24px 20px;

      .search-brand__input-email {
        display: block;
        width: 100%;
        height: 56px;
        padding: 10px 7px;
        border: 1px solid #747474;
        border-radius: 4px;
        outline:none;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        box-sizing: border-box;
  
        &:focus{
          color: #333333;
          border: 2px solid #333333;
          ;
        }
  
        &::-webkit-input-placeholder { color: #b3b3b3; }
        &:-ms-input-placeholder      { color: #b3b3b3; }
        &::-moz-placeholder          { color: #b3b3b3; }
      }    
  
  
      .search-brand__content-sub-group {
        display:none;
        height: 350px;
        overflow: auto;
  
        &.active {
          display: block;

          .search-brand__content-content {
            font-size: 14px;
            font-weight: 400;
            color: #979797;
          }

          // 검색된 브랜드 목록
          .search-brand__brand-list-box{
            margin: 20px 0;
            cursor: pointer;

            &:hover {
              background: #DCDFE2;
            }

            &.selected {
              background: #FF662A;
            }

            >div {
              margin-bottom: 4px;

              >span:nth-of-type(1) {
                color: #333333;
                font-size: 16px;
                font-weight: 400;
                margin-right: 8px;
              }
              >span:nth-of-type(2) {
                color: #979797;
                font-size: 12px;
                font-weight: 400;
              }
            }

            >span{
              color: #979797;
              font-size: 12px;
              font-weight: 400;
            }

          }
        }
      }

    }
    
    .search-brand__content-group-divider {
      display:none;

      &.active {
        display:block;
        border-bottom: 8px solid #DCDFE2;
      }
    }

    .search-brand__content-group-footer {
      display:none;

      &.active {
        display:block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;

        span{
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
        button {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          border: 1px solid #DCDFE2;
          border-radius: 4px;
          padding: 4px 8px;
          box-sizing: border-box;

          
        }
      }

    }

    
    
  }
}