
//Login message
.marketing-info-recieve__container {
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background: #fff;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  .marketing-info-recieve__title-group {
    display:flex;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    padding: 20px 20px;
    border-bottom: 1px solid #B3B3B3;

    .marketing-info-recieve__title {
      color: #FF662A;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
    }
    .marketing-info-recieve__close-btn {
      background: url('../images/icon/icon_close.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width:18px;
      height:18px;
      display:block;
      text-indent: -9999px;
      cursor: pointer;
    }
  }

  .marketing-info-recieve__content-group {
    display:flex;
    width: 100%;
    height: 500px;
    flex-direction: column;
    padding: 30px 20px;
    box-sizing: border-box;
    overflow: auto;
    
    h1 {
      font-size: 16px;
      font-weight: 700;
      color: #333333;
      margin-bottom: 12px;
    }
    h2 {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 32px;
    }

    h3 {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 12px;
    }

    div {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }

    .marketing-info-recieve__content-content {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 34px;
    }

  }
}