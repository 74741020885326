.join-modal {
  &.email {
    padding: 40px;
    overflow: auto;
  }
  &.on {
    padding: 40px;
    overflow: auto;
  }
}

.join__container {
  outline: none;

  .join-section {
    height: 100vh;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &.email {
      height: 100%;
      margin: 0;
    }
    &.on {
      height: 100%;
      margin: 0;
    }

    .join-inner-section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0 0 4px #bebebe;
      box-sizing: border-box;
      border-radius: 4px;

      .join__title-group {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px;
        border-bottom: 1px solid #b3b3b3;
        background: #fff;
        border-radius: 4px 4px 0 0;
        background: #fff;

        .join__title {
          color: #ff662a;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.4;
        }
        .join__close-btn {
          background: url('../images/icon/icon_close.svg');
          background-repeat: no-repeat;
          background-size: contain;
          width: 18px;
          height: 18px;
          display: block;
          text-indent: -9999px;
          cursor: pointer;
        }
      }

      .join {
        width: 582px;
        box-sizing: border-box;
        position: relative;
        background: #fff;
        border-radius: 0 0 4px 4px;

        &.hide {
          display: none;
        }

        &.custom1 {
          padding: 0 102px 10px;
        }

        .join__menu {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 46px 0 0;

          button {
            display: block;
            text-align: center;
            width: 99px;
            padding-bottom: 10px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            box-sizing: border-box;
            text-decoration: none;
            color: #b3b3b3;
            border-bottom: 2px solid #b3b3b3;
            position: relative;

            &:first-child {
              margin-right: 20px;
            }
            &.on {
              display: block;
              font-weight: 700;
              color: #ff662a;
              border-bottom: 3px solid #ff662a;
            }

            &:hover::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              border-bottom: 2px solid rgba(0, 0, 0, 0.075);
            }
          }
        }

        //회원가입 최초 화면
        .join__join-first-group {
          display: none;
          padding: 0 102px 47px;
          box-sizing: border-box;

          &.active {
            display: block;

            .join__btn {
              background-color: #ff662a;
              height: 56px;
              font-size: 16px;
              font-weight: 400;
              color: #fff;
              display: flex;
              width: 100%;
              padding: 16px;
              margin: 24px 0 21px 0;
              justify-content: center;
              align-items: center;
              text-decoration: none;
              box-sizing: border-box;
              border-radius: 4px;
              position: relative;

              &:hover::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.075);
              }
              &.inactive {
                background-color: #dcdfe2;
              }

              &.sns__naver {
                background: url('../images/icon/icon_naver.png') #2db400;
                background-repeat: no-repeat;
                background-size: 60px 60px;
                background-position: 0 50%;
                width: 100%;
                height: 60px;
                display: block;
                border-radius: 4px;
                margin: 24px 0 21px 0;
                padding: 16px;
                box-sizing: border-box;
                position: relative;
              }

              &.sns__google {
                background: url('../images/icon/icon_google.png') #eee;
                background-repeat: no-repeat;
                background-size: 60px 60px;
                background-position: 0 50%;
                width: 100%;
                height: 60px;
                display: block;
                border-radius: 4px;
                margin: 24px 0 21px 0;
                padding: 16px;
                box-sizing: border-box;
                position: relative;
              }

              &.sns__kakao {
                background: url('../images/icon/icon_kakao.png') #ffe812;
                background-repeat: no-repeat;
                background-size: 60px 60px;
                background-position: 0 50%;
                width: 100%;
                height: 60px;
                display: block;
                border-radius: 4px;
                margin: 24px 0 21px 0;
                padding: 16px;
                box-sizing: border-box;
                position: relative;
              }
            }

            .join__already-had-account {
              display: flex;
              justify-content: space-between;
              align-items: center;
              box-sizing: border-box;
              padding-bottom: 14px;

              &.inactive {
                display: none;
              }

              span {
                font-size: 12px;
                font-weight: 400;
                color: #979797;
                &:last-child {
                  cursor: pointer;
                }
              }

              button {
                font-size: 12px;
                color: #000;
                line-height: 1.4;
                text-decoration: underline;

                &:hover {
                  color: rgba(0, 0, 0, 0.75);
                }
              }
            }
          }
        }

        //회원가입 이메일 필드
        .join__join-second-group {
          display: none;
          padding: 0 102px 47px;
          box-sizing: border-box;

          &.isCorp {
            display: none;
            &.active {
              display: block;
              .join__input {
                margin-top: 44px;

                .join__email {
                  position: relative;

                  .join__email-input-box {
                    display: block;
                    width: 100%;
                    height: 56px;
                    padding: 10px 7px;
                    margin: 0 0 21px 0;
                    border: 1px solid #747474;
                    border-radius: 4px;
                    outline: none;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    box-sizing: border-box;

                    &:focus {
                      color: #333333;
                      border: 2px solid #333333;
                    }

                    &:focus + .join__email-title {
                      color: #333333;
                    }
                  }

                  .join__email-title {
                    font-size: 12px;
                    font-weight: 400;
                    color: #747474;
                    position: absolute;
                    top: -10px;
                    left: 6px;
                    padding: 2px;
                    background: #fff;
                  }
                }

                .join__password {
                  position: relative;

                  .join__password-input-box {
                    display: block;
                    width: 100%;
                    height: 56px;
                    padding: 10px 7px;
                    margin: 0 0 21px 0;
                    border: 1px solid #747474;
                    border-radius: 4px;
                    outline: none;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    box-sizing: border-box;

                    &:focus {
                      color: #333333;
                      border: 2px solid #333333;
                    }

                    &:focus + .join__password-title {
                      color: #333333;
                    }
                  }

                  .join__password-title {
                    font-size: 12px;
                    font-weight: 400;
                    color: #747474;
                    position: absolute;
                    top: -10px;
                    left: 6px;
                    padding: 2px;
                    background: #fff;
                  }
                }
              }

              .join__agree-title-group {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;

                .join__agree-title {
                  font-weight: 500;
                }

                .check-primary {
                  input[type='checkbox'] {
                    width: 18px;
                    height: 18px;
                    border: 2px solid #b1bac3;
                    border-radius: 2px;
                    background-image: url(../images/icon/icon_checkbox_small_default.png);
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: contain;

                    &:checked {
                      background-color: #ff662a;
                      border-color: #ff662a;
                      background-image: url(../images/icon/icon_checkbox_small_select.png);
                      background-position: 50% 50%;
                      background-repeat: no-repeat;
                      background-size: contain;
                    }
                  }
                  span {
                    padding-left: 5px;
                    color: #333333;
                    font-size: 14px;
                    font-weight: 400;
                  }
                }
              }

              .divider {
                height: 3px;
                background: #dcdfe2;
              }

              .join__agree-check-box {
                margin-top: 12px;
                margin-bottom: 8px;

                .join__agree-check-row {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  margin-bottom: 4px;

                  .check-primary {
                    input[type='checkbox'] {
                      width: 18px;
                      height: 18px;
                      border: 2px solid #b1bac3;
                      border-radius: 2px;
                      background-image: url(../images/icon/icon_checkbox_small_default.png);
                      background-position: 50% 50%;
                      background-repeat: no-repeat;
                      background-size: contain;
                      &:checked {
                        background-color: #ff662a;
                        border-color: #ff662a;
                        background-image: url(../images/icon/icon_checkbox_small_select.png);
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                        background-size: contain;
                      }
                    }
                    span {
                      padding-left: 5px;
                      color: #979797;
                      font-size: 12px;
                      font-weight: 400;
                    }
                  }

                  // 내용보기
                  .join__agree-view-text {
                    font-size: 12px;
                    font-weight: 400;
                    color: #333333;
                    padding: 2px;
                    text-decoration: underline;
                  }
                }
              }

              .join__btn {
                background-color: #ff662a;
                height: 56px;
                font-size: 16px;
                font-weight: 400;
                color: #fff;
                display: flex;
                width: 100%;
                padding: 16px;
                margin: 24px 0 10px 0;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                box-sizing: border-box;
                border-radius: 4px;
                position: relative;

                &:hover::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  width: 100%;
                  height: 100%;
                  background: rgba(0, 0, 0, 0.075);
                }
                &.inactive {
                  background-color: #dcdfe2;
                }
              }

              .join__already-had-account {
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding-bottom: 14px;

                &.inactive {
                  display: none;
                }

                span {
                  font-size: 12px;
                  font-weight: 400;
                  color: #979797;
                  &:last-child {
                    cursor: pointer;
                  }
                }

                button {
                  font-size: 12px;
                  color: #000;
                  line-height: 1.4;
                  text-decoration: underline;

                  &:hover {
                    color: rgba(0, 0, 0, 0.75);
                  }
                }
              }
            }
          }
        }

        //기업 고객
        .join__join-third-group {
          display: none;
          padding: 0 0 47px;
          box-sizing: border-box;

          &.active {
            &.isCorp {
              display: block;

              //브랜드 선택 / 사업자 등록증
              .join__select-group {
                padding: 0 102px 0;
                box-sizing: border-box;
                margin-bottom: 52px;

                &.mt60 {
                  margin-top: 44px;
                }

                .join__select-group-divider {
                  height: 3px;
                  background: #dcdfe2;
                }

                .join__select-group-row {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 4px;

                  .join__select-title {
                    font-weight: 500;
                  }
                  .join__select-search {
                    color: #bebebe;
                    border: 1px solid #bebebe;
                    border-radius: 4px;
                    padding: 4px 8px;
                  }
                }

                .join__brand-group {
                  position: relative;
                  padding: 6px 0;

                  &:hover {
                    background: #eee;
                  }

                  //버튼 닫기
                  .join__brand-group-close-btn {
                    position: absolute;
                    top: 6px;
                    right: 0;
                    background: url('../images/icon/icon_close.png');
                    background-repeat: no-repeat;
                    background-size: 10px 10px;
                    background-position: 50% 50%;
                    width: 16px;
                    height: 16px;
                    display: block;
                    text-indent: -9999px;
                    border: 1px dashed #dcdfe2;
                    cursor: pointer;
                  }

                  .join__brand-group-row {
                    font-size: 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .join__brand-group-body {
                      display: flex;
                      align-items: center;

                      .join__title-header {
                        font-weight: 500;
                        color: #333333;
                        width: 84px;
                      }
                      .join__title-data {
                        font-weight: 400;
                        color: #979797;

                        &.underline {
                          text-decoration: underline;
                          cursor: pointer;
                        }

                        &.upload-name {
                          display: inline-block;
                          height: 20px;
                          /* label의 패딩값과 일치 */
                          font-size: 8px;
                          line-height: 1.4;
                          border: none;
                          background: #fff;
                          vertical-align: middle;
                          -webkit-appearance: none;
                          /* 네이티브 외형 감추기 */
                          -moz-appearance: none;
                          appearance: none;
                        }
                      }
                    }
                    .join__title-tailing {
                      font-size: 12px;

                      &.filebox {
                        input[type='file'] {
                          position: absolute;
                          width: 1px;
                          height: 1px;
                          padding: 0;
                          margin: -1px;
                          overflow: hidden;
                          clip: rect(0, 0, 0, 0);
                          border: 0;
                        }

                        label {
                          display: inline-block;
                          padding: 0 4px;
                          color: #999;
                          font-size: inherit;
                          line-height: normal;
                          vertical-align: middle;
                          background-color: #fdfdfd;
                          cursor: pointer;
                          border: 1px solid #ebebeb;
                          border-bottom-color: #e2e2e2;
                          border-radius: 0.25em;
                        }

                        button {
                          font-size: 12px;
                          border: 1px solid #dcdfe2;
                          border-radius: 2px;
                          padding: 0 4px;
                          box-sizing: border-box;
                        }
                      }
                    }
                  }
                }

                .no-selected-brand-data {
                  text-align: center;
                  margin: 30px 0 0;
                  font-size: 14px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 1.7;
                }
              }
            }
          }
        }
        .join__join-fourth-group {
          display: none;
          padding: 0 102px 0;
          box-sizing: border-box;

          &.active {
            &.isCorp {
              display: block;

              .join__corp-title-group {
                color: #333;
                font-size: 14px;
                font-weight: 700;
                margin: 32px 0 30px 0;
              }

              //브랜드 선택 / 사업자 등록증
              .join__select-group {
                .join__select-group-row {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 20px;

                  .join__select-title {
                    font-weight: 500;
                  }
                  .join__select-search {
                    color: #bebebe;
                    width: 140px;
                    border: 1px solid #bebebe;
                    border-radius: 4px;
                    padding: 4px 8px;
                  }
                }
              }

              // 담당자 정보
              .join__input {
                .join__input-group {
                  position: relative;
                  margin: 0 0 21px 0;

                  &.margin-bottom-min {
                    margin-bottom: 4px;
                  }

                  .join__input-box {
                    display: block;
                    width: 100%;
                    height: 56px;
                    padding: 10px 7px;
                    border: 1px solid #747474;
                    border-radius: 4px;
                    outline: none;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    box-sizing: border-box;

                    &::-webkit-input-placeholder {
                      color: #b3b3b3;
                      font-size: 14px;
                    }
                    &:-ms-input-placeholder {
                      color: #b3b3b3;
                      font-size: 14px;
                    }
                    &::-moz-placeholder {
                      color: #b3b3b3;
                      font-size: 14px;
                    }

                    &:focus {
                      color: #333333;
                      border: 2px solid #333333;
                    }

                    &:focus + .join__input-title {
                      color: #333333;
                    }
                  }

                  .join__input-title {
                    font-size: 12px;
                    font-weight: 400;
                    color: #747474;
                    position: absolute;
                    top: -10px;
                    left: 6px;
                    padding: 2px;
                    background: #fff;
                  }

                  .join__password-input-box {
                    display: block;
                    width: 100%;
                    height: 56px;
                    padding: 10px 7px;
                    border: 1px solid #747474;
                    border-radius: 4px;
                    outline: none;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    box-sizing: border-box;

                    &::-webkit-input-placeholder {
                      color: #b3b3b3;
                      font-size: 14px;
                    }
                    &:-ms-input-placeholder {
                      color: #b3b3b3;
                      font-size: 14px;
                    }
                    &::-moz-placeholder {
                      color: #b3b3b3;
                      font-size: 14px;
                    }

                    &:focus {
                      color: #333333;
                      border: 2px solid #333333;
                    }

                    &:focus + .join__password-title {
                      color: #333333;
                    }
                  }

                  .join__password-title {
                    font-size: 12px;
                    font-weight: 400;
                    color: #747474;
                    position: absolute;
                    top: -10px;
                    left: 6px;
                    padding: 2px;
                    background: #fff;
                  }
                }
              }

              .join__agree-title-group {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;

                .join__agree-title {
                  font-weight: 500;
                }

                .checkbox {
                  display: inline-block;
                  input[type='checkbox'] {
                    display: none;

                    & + i {
                      display: inline-block;
                      width: 24px;
                      height: 24px;
                      border: 1px solid #b3b3b3;
                      background-image: url(../images/icon/icon_checkbox_large_default.png);
                      background-repeat: no-repeat;
                      background-position: 50% 50%;
                    }
                    &:checked + i {
                      border-color: #ff662a;
                      background-image: url(../images/icon/icon_checkbox_large_select.png);
                    }
                  }

                  &.check-primary {
                    input[type='checkbox'] {
                      & + i {
                        width: 18px;
                        height: 18px;
                        border: 2px solid #b1bac3;
                        border-radius: 2px;
                        background-image: url(../images/icon/icon_checkbox_small_default.png);
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                        background-size: contain;
                      }
                      &:checked + i {
                        background-color: #ff662a;
                        border-color: #ff662a;
                        background-image: url(../images/icon/icon_checkbox_small_select.png);
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                        background-size: contain;
                      }
                    }
                    span {
                      padding-left: 5px;
                      color: #333333;
                      font-size: 14px;
                      font-weight: 400;
                    }
                  }
                }
              }

              .divider {
                height: 3px;
                background: #dcdfe2;
              }

              .join__agree-check-box {
                margin-top: 12px;
                margin-bottom: 8px;

                &.margin-min {
                  margin-top: 0;
                  margin-bottom: 24px;
                }

                .join__agree-check-row {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  margin-bottom: 4px;

                  .checkbox {
                    display: inline-block;
                    input[type='checkbox'] {
                      display: none;

                      & + i {
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        border: 1px solid #b3b3b3;
                        background-image: url(../images/icon/icon_checkbox_large_default.png);
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                      }
                      &:checked + i {
                        border-color: #ff662a;
                        background-image: url(../images/icon/icon_checkbox_large_select.png);
                      }
                    }

                    &.check-primary {
                      input[type='checkbox'] {
                        & + i {
                          width: 18px;
                          height: 18px;
                          border: 2px solid #b1bac3;
                          border-radius: 2px;
                          background-image: url(../images/icon/icon_checkbox_small_default.png);
                          background-position: 50% 50%;
                          background-repeat: no-repeat;
                          background-size: contain;
                        }
                        &:checked + i {
                          background-color: #ff662a;
                          border-color: #ff662a;
                          background-image: url(../images/icon/icon_checkbox_small_select.png);
                          background-position: 50% 50%;
                          background-repeat: no-repeat;
                          background-size: contain;
                        }
                      }
                      span {
                        padding-left: 5px;
                        color: #979797;
                        font-size: 12px;
                        font-weight: 400;
                      }
                    }
                  }

                  // 내용보기
                  .join__agree-view-text {
                    font-size: 12px;
                    font-weight: 400;
                    color: #333333;
                    padding: 2px;
                    text-decoration: underline;
                  }
                }
              }

              .join__btn {
                background-color: #ff662a;
                height: 56px;
                font-size: 16px;
                font-weight: 400;
                color: #fff;
                display: flex;
                width: 100%;
                padding: 16px;
                margin: 24px 0 10px 0;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                box-sizing: border-box;
                border-radius: 4px;
                position: relative;

                &:hover::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  width: 100%;
                  height: 100%;
                  background: rgba(0, 0, 0, 0.075);
                }
                &.inactive {
                  background-color: #dcdfe2;
                }
              }

              .join__already-had-account {
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding-bottom: 14px;

                &.inactive {
                  display: none;
                }

                span {
                  font-size: 12px;
                  font-weight: 400;
                  color: #979797;

                  &:last-child {
                    cursor: pointer;
                  }
                }

                button {
                  font-size: 12px;
                  color: #000;
                  line-height: 1.4;
                  text-decoration: underline;

                  &:hover {
                    color: rgba(0, 0, 0, 0.75);
                  }
                }
              }
            }
          }
        }
      } // join end

      .divider {
        width: 100%;
        height: 8px;
        position: relative;
        background: #dcdfe2;

        &.hide {
          display: none;
        }
      }
    }
  } // login-section
} //join__container
