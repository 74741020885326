#root {
  .main-container {
    position: relative;
    top: 79px;
    left: 0;
    .main-cover {
      position: relative;
      .slick-slider {
        .cover-item {
          position: relative;
          max-width: 1280px;
          height: 460px;
          margin: 0 auto;

          &:nth-of-type(1) {
            figure {
              .thumb {
                position: absolute;
                right: 30px;
                top: 94px;
              }
              figcaption {
                display: block;
                padding: 100px 0 0 100px;
                text-align: left;
                .title {
                  color: #ff662a;
                  font-size: 44px;
                  line-height: 1.23;
                }
                .sub {
                  margin: 20px 0 40px;
                  color: #979797;
                  font-size: 16px;
                }
              }
            }
          }
        }

        .slick-dots {
          left: 0;
          right: 0;
          bottom: 30px;
          .paging-container {
            max-width: 1280px;
            margin: 0 auto;
            padding-left: 100px;
            text-align: left;
          }
        }
      }

      .arrow-container {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        .slick-arrow {
          width: 48px;
          height: 48px;
          border: 1px solid #dcdfe2;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-color: #fff;
          &.slick-prev {
            left: 0;
            top: 0;
            background-image: url(../images/icon/icon_arrow_large_left_default.svg);
            &::before {
              content: '';
            }
          }
          &.slick-next {
            right: 0;
            top: 0;
            background-image: url(../images/icon/icon_arrow_large_right_default.svg);
            &::before {
              content: '';
            }
          }
        }
      }
    }
  }

  .section-theme {
    padding: 100px 0 60px;
    &:nth-of-type(2n) {
      background-color: #f4faf7;
    }
  }

  //플로팅 버튼
  //채팅
  .main__floating-chatting {
    position: fixed;
    bottom: 20px;
    right: 35px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: url('../images/icon/icon_channel_default.png');
    background-repeat: no-repeat;
    background-position: 50% 39%;
    background-size: 100px 100px;
    box-shadow: 0 0 2px #dcdfe2;
    border-radius: 50%;
    text-indent: -9999px;
    box-sizing: border-box;
    z-index: 101;

    &.hide {
      display: none;
    }

    &:hover {
      background: url('../images/icon/icon_channel_hover.png');
      position: fixed;
      bottom: 20px;
      right: 35px;
      width: 60px;
      height: 60px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: 50% 39%;
      background-size: 100px 100px;
      box-shadow: 0 0 2px #dcdfe2;
      border-radius: 50%;
      text-indent: -9999px;
      box-sizing: border-box;
      z-index: 101;
    }
  }

  //비교하기
  .main__floating-compare {
    position: fixed;
    bottom: 100px;
    right: 35px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: url('../images/icon/icon_comparison_default.png');
    background-repeat: no-repeat;
    background-position: 50% 39%;
    background-size: 100px 100px;
    box-shadow: 0 0 2px #dcdfe2;
    border-radius: 50%;
    text-indent: -9999px;
    box-sizing: border-box;
    z-index: 101;

    &.hide {
      display: none;
    }

    &:hover {
      position: fixed;
      bottom: 100px;
      right: 35px;
      width: 60px;
      height: 60px;
      cursor: pointer;
      background: url('../images/icon/icon_comparison_hover.png');
      background-repeat: no-repeat;
      background-position: 50% 39%;
      background-size: 100px 100px;
      box-shadow: 0 0 2px #dcdfe2;
      border-radius: 50%;
      text-indent: -9999px;
      box-sizing: border-box;
      z-index: 101;
    }
  }
}

#header__order-group-select-box-container {
  display: block;
  padding: 20px 24px;
  border-radius: 6px;
  box-shadow: 0 0 2px #dcdfe2;
  box-sizing: border-box;

  .user-name {
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
  }

  .user-type {
    color: #333333;
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin-bottom: 4px;
  }

  .user-email {
    color: #333333;
    font-size: 12px;
    font-weight: 300;
    display: block;
  }
  .divier {
    border-bottom: 1px solid #dcdfe2;
    margin: 20px 0;
  }

  .header__order-group-select-box-item {
    display: block;
    box-sizing: border-box;
    margin: 12px 0;

    &:hover {
      cursor: pointer;
    }
  }
}
