@import 'mixin';
.MuiAutocomplete-popper {
  .MuiPaper-root {
    box-shadow: none;
    text-align: center;
    .MuiAutocomplete-noOptions {
      font-family: 'Noto Sans', 'Open Sans', 'Nanum Gothic', 'Dotum', sans-serif;
      font-size: 1em;
    }

    .MuiAutocomplete-listbox {
      max-height: 300px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
      }
 
    }
  }
}

.brand-container {
  position:relative;
  top: 79px;
  left: 0;
  
  .search-brand {
    padding: 80px 0;
    background-color: #fff;

    .search {
      display: block;
      width: 582px;
      height: 70px;
      margin: 0 auto;

      //검색 키워드 영역
      .search-box {
        position: relative;   
        width: 100%;
        height: 100%;     
        border: 4px solid #ff662a;

        .ic-search {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 60px;
          @include ic-search;
        }
  
        .search-input {
          height: 100%;
          input {
            width: 100%;
            height: 100%;
            padding: 0 20px 0 60px;
            &::placeholder {
              color: #b3b3b3;
              font-size: 1em;
            }
          }
        }
      }

      
      //검색 결과 영역
      .result-list-group {
        display: none;
        
        overflow-y: auto;
        border-right: 1px solid #b3b3b3;
        border-left: 1px solid #b3b3b3;
        border-bottom: 1px solid #b3b3b3;
        z-index: 106;
        background: #fff;
        position: relative;

        &.on {
          display: block;

          .brand-item {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 5px 8px;
            box-sizing: border-box;
    
            &:hover {
              background-color: #f7f8fa;
            }
            
            .brand-name {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
    
              
              .keyword-color {
                color: #ff662a;
              }
              
              .thumb-logo {
                overflow: hidden;
                position: relative;
                width: 36px;
                height: 36px;
                border-radius: 4px;
                background-color: #fff;
                margin-right: 10px;
                border: 1px solid #DCDFE2;
    
                img {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  @include elem-pos(-50%, -50%);
                }
              }
              .name {
                margin-left: 10px;
                font-weight: 300;
              }
            }
    
            .category-name {
              color: #979797;
              font-size: 0.9em;
            }
          }
        }

        
      }
      

      //창닫기 버튼 영역
      .btn-group{
        display: none;

        &.on {
          display: flex;
          z-index: 106;
          background: #fff;
          position: relative;
          border-right: 1px solid #b3b3b3;
          border-left: 1px solid #b3b3b3;
          border-bottom: 1px solid #b3b3b3;
          justify-content: flex-end;
          padding: 10px;
          box-sizing: border-box;

          .close-btn {
            cursor: pointer;

            .close-img {
              background-image: url(../images/icon/icon_x_4.png);
              background-repeat: no-repeat;
              width: 12px;
              height: 12px;
              text-indent: -9999px;
              display: inline-block;
            }
  
            .search-brand-close-btn {
              padding-left: 4px;
            }
          }
          
        }
        
      }
      

    }
  }


  .inner {
    .brand__list-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      
      .item {
        margin: 0 0 60px 58px;

        &:nth-of-type(4n + 1) {
          margin-left: 0px;
        }
      }
    }
    .brand__list-check-category {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

#root {
  .btn-close-search {
    display: none;
  }
}
