

.admin-branch__container {
  width: 1284px;
  margin: auto;
  padding: 20px;

  //브랜드관리 - 브랜드 정보 탭
  .info-tab__container {
    display: flex;
    padding: 10px;  
    height: calc(100vh - 120px); 

    .left-group{
      width: 200px;
      padding: 0 24px 24px;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #eeeeee;

      .inner-main {
        flex: 1;
        overflow: auto; 

        .custom-section-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 8px;
          margin-bottom: 8px;
          border: 1px solid #FF662A;
          border-radius: 4px;
          color: #FF662A;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          cursor: pointer;
          position: relative;
          box-sizing:border-box;

          &:hover::before {
            content: "";
            position: absolute;
            top:0;
            right:0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.16);
            border-radius: 4px;
            box-sizing:border-box;
          }
          
          &.selected {
            background: #FF662A;
            color: #ffffff;
          }
          &.warning {
            color: #fff;
            background-color: #F00;
          }
        }
      }
      .inner-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 8px;
        background:#FF662A;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        cursor: pointer;
        position: relative;
        box-sizing:border-box;

        &.mb10 {
          margin-bottom: 10px;
        }

        &:hover::before {
          content: "";
          position: absolute;
          top:0;
          right:0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.16);
          border-radius: 4px;
          box-sizing:border-box;
        }
      }
    }

    .right-group {
      flex: 1;
      padding: 0 24px 24px;
    
      .header-group {
        box-sizing: border-box;
        border-bottom: 2px solid #FF662A;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 4px;
    
        .button-group {
          display: flex;
          

          
          
          
          .action-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 12px;
            background:#FF662A;
            border-radius: 4px;
            color: #FFf;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
            cursor: pointer;
            position: relative;
            padding: 8px 14px;
            box-sizing:border-box;
            margin-right: 8px;
    
            
            &:last-child {
              margin-right: 0;
            }
    
            &:hover::before {
              content: "";
              position: absolute;
              top:0;
              right:0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,.16);
              border-radius: 4px;
              box-sizing:border-box;
            }
          }

          
          
        }
      }

      
    }
  }


  //머티리얼 탭 UI 수정
  .MuiTabs-root {
    
    .MuiTabs-scroller {
      &.MuiTabs-fixed {

        &.MuiTabs-fixed{

          .PrivateTabIndicator-root-1 {
            &.PrivateTabIndicator-colorPrimary-2 {
              background-color: #fff;
              border-bottom: 4px solid #B3B3B3;
              &.MuiTabs-indicator{
                border-bottom: 4px solid #FF662A;
              }
            }
          }
        }

        .MuiTabs-flexContainer + span {
          background-color: #FF662A;
        }

        .MuiTabs-flexContainer {
          .MuiButtonBase-root {
            &.MuiTab-root {
              
                
              &.MuiTab-textColorInherit { 
                font-size: 18px;
                font-weight: 400;  
                color: #B3B3B3;

                &.Mui-selected {
                  position: relative; 
                  .MuiTab-wrapper {
                    color: #FF662A;  
                    font-weight: 700;     
                  }
                  .MuiTouchRipple-root::before{
                    content: "";
                    display:block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
  
                  }
                }
              }
            }        
          }
        }
      }
    }
  }
}

.excel-upload-body-group {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 564px;
}

.admin-branch__modal {
  height: 100%;
  z-index: 9999;

  .circular-progress-box {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .circular-progress {
      color: #fff;
    }
    .upload-text {
      color: #fff;

    }
  }
}