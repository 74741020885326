.admin-my-page {
  position: relative;
  top: 129px; //79px
  left: 0;
  margin: 0 auto;
  width: 378px;

  .admin-title {
    margin-bottom: 30px;
  }
  // 담당자 정보
  .join__input {
    .join__input-group {
      position: relative;
      margin: 0 0 21px 0;

      &.margin-bottom-min {
        margin-bottom: 4px;
      }

      .join__input-box {
        display: block;
        width: 100%;
        height: 56px;
        padding: 10px 7px;
        border: 1px solid #747474;
        border-radius: 4px;
        outline: none;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        box-sizing: border-box;

        &::-webkit-input-placeholder {
          color: #b3b3b3;
          font-size: 14px;
        }
        &:-ms-input-placeholder {
          color: #b3b3b3;
          font-size: 14px;
        }
        &::-moz-placeholder {
          color: #b3b3b3;
          font-size: 14px;
        }

        &:focus {
          color: #333333;
          border: 2px solid #333333;
        }

        &:focus + .join__input-title {
          color: #333333;
        }
      }

      .join__input-title {
        font-size: 12px;
        font-weight: 400;
        color: #747474;
        position: absolute;
        top: -10px;
        left: 6px;
        padding: 2px;
        background: #fff;
      }

      .join__password-input-box {
        display: block;
        width: 100%;
        height: 56px;
        padding: 10px 7px;
        border: 1px solid #747474;
        border-radius: 4px;
        outline: none;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        box-sizing: border-box;

        &::-webkit-input-placeholder {
          color: #b3b3b3;
          font-size: 14px;
        }
        &:-ms-input-placeholder {
          color: #b3b3b3;
          font-size: 14px;
        }
        &::-moz-placeholder {
          color: #b3b3b3;
          font-size: 14px;
        }

        &:focus {
          color: #333333;
          border: 2px solid #333333;
        }

        &:focus + .join__password-title {
          color: #333333;
        }
      }

      .join__password-title {
        font-size: 12px;
        font-weight: 400;
        color: #747474;
        position: absolute;
        top: -10px;
        left: 6px;
        padding: 2px;
        background: #fff;
      }
    }
  }

  .sub-title {
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 8px;
  }
  .divider {
    border-bottom: 3px solid #dcdfe2;
    margin-bottom: 12px;
  }
  .my-page__change-btn {
    margin: 24px 0 30px 0;
  }

  .join__agree-title-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .join__agree-title {
      font-weight: 500;
    }

    .checkbox {
      display: inline-block;
      input[type='checkbox'] {
        display: none;

        & + i {
          display: inline-block;
          width: 24px;
          height: 24px;
          border: 1px solid #b3b3b3;
          background-image: url(../images/icon/icon_checkbox_large_default.png);
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
        &:checked + i {
          border-color: #ff662a;
          background-image: url(../images/icon/icon_checkbox_large_select.png);
        }
      }

      &.check-primary {
        input[type='checkbox'] {
          & + i {
            width: 18px;
            height: 18px;
            border: 2px solid #b1bac3;
            border-radius: 2px;
            background-image: url(../images/icon/icon_checkbox_small_default.png);
            background-position: 50% 50%;
            background-size: contain;
          }
          &:checked + i {
            background-color: #ff662a;
            border-color: #ff662a;
            background-image: url(../images/icon/icon_checkbox_small_select.png);
            background-position: 50% 50%;
            background-size: contain;
          }
        }
        span {
          padding-left: 5px;
          color: #333333;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .divider {
    height: 3px;
    background: #dcdfe2;
  }

  .join__agree-check-box {
    margin-top: 12px;
    margin-bottom: 8px;

    &.margin-min {
      margin-top: 0;
      margin-bottom: 24px;
    }

    .join__agree-check-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 4px;

      .checkbox {
        display: inline-block;
        input[type='checkbox'] {
          display: none;

          & + i {
            display: inline-block;
            width: 24px;
            height: 24px;
            border: 1px solid #b3b3b3;
            background-image: url(../images/icon/icon_checkbox_large_default.png);
            background-repeat: no-repeat;
            background-position: 50% 50%;
          }
          &:checked + i {
            border-color: #ff662a;
            background-image: url(../images/icon/icon_checkbox_large_select.png);
          }
        }

        &.check-primary {
          input[type='checkbox'] {
            & + i {
              width: 18px;
              height: 18px;
              border: 2px solid #b1bac3;
              border-radius: 2px;
              background-image: url(../images/icon/icon_checkbox_small_default.png);
              background-position: 50% 50%;
              background-size: contain;
            }
            &:checked + i {
              background-color: #ff662a;
              border-color: #ff662a;
              background-image: url(../images/icon/icon_checkbox_small_select.png);
              background-position: 50% 50%;
              background-size: contain;
            }
          }
          span {
            padding-left: 5px;
            color: #979797;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }

      // 내용보기
      .join__agree-view-text {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        padding: 2px;
        text-decoration: underline;
      }
    }
  }

  .join__btn {
    background-color: #ff662a;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    display: flex;
    width: 100%;
    padding: 16px;
    margin: 24px 0 10px 0;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;

    &:hover::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.075);
    }
    &.inactive {
      background-color: #dcdfe2;
    }
  }

  .join__already-had-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 14px;

    &.inactive {
      display: none;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: #979797;

      &:last-child {
        cursor: pointer;
      }
    }

    button {
      font-size: 12px;
      color: #000;
      line-height: 1.4;
      text-decoration: underline;

      &:hover {
        color: rgba(0, 0, 0, 0.75);
      }
    }
  }

  .my-page__save-btn {
    margin: 24px 0 12px 0;
  }
  .my-page__change-btn,
  .my-page__save-btn {
    background-color: #ff662a;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;

    &:hover::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.075);
    }
    &.inactive {
      background-color: #dcdfe2;
    }
  }

  .delete-user {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    border-bottom: 1px solid #333333;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;

    &:hover {
      color: #747474;
      border-bottom: 1px solid #747474;
    }
  }
  .height80 {
    width: 1px;
    height: 80px;
  }
}
