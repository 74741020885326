.coin__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  position: relative;
  margin-bottom: 20px;
  outline:none;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.016);
    border-radius: 10px;
  }

  .left-group {
    display: flex;
    align-items: center;

    .coin-img {
      width: 20px;
      height: 20px;
      background: url("../images/icon/icon_coin.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      text-indent: -9999px;
      margin-right: 10px;
    }
    .coin-label {
      font-size: 14px;
      font-weight: 400;
      margin-right: 10px;
    }
    .coin-cnt {
      font-size: 14px;
      font-weight: 700;
      color: #ff662a;
    }
  }

  .right-group {
    .charge-text {
      font-size: 14px;
      font-weight: 400;
      color: #505752;
      cursor: pointer;
    }
  }
}

.coin-modal-popup{
  overflow: auto;
  outline: none;


  .coin-tap__container {
    margin: auto;
    padding: 100px 0;
    width: 378px;
    
    .coin-section {
      background: #fff;
      border-radius: 6px;

      .title-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px;
        border-bottom: 1px solid #b3b3b3;
        
        .title{
          color: #ff662a;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.4;
        }
        .close-popup {
          background: url('../images/icon/icon_close.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 18px;
          height: 18px;
          display: block;
          text-indent: -9999px;
          cursor: pointer;
        }
      }
      
      .tab-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        border-bottom: 1px solid #dedddd;

        .tab {
          flex: 1;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;
          padding: 20px 20px;

          &.active {
            position: relative;
            color: #ff662a;

            &::before {
              position: absolute;
              content: "";
              display:block;
              border-bottom: 3px solid #ff662a;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              
            }
          }
        }
      }

      .tab__container {
        display: none;

        &.active {
          display: block;

          //현재 보유 중인 코인
          .my-coin-cnt {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #bdbdbd;
            position: relative;

            ::before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0,0,0, 0.016);
            }

            .coin-img {
              width: 20px;
              height: 20px;
              background: url("../images/icon/icon_coin.png");
              background-repeat: no-repeat;
              background-size: 20px 20px;
              text-indent: -9999px;
              margin-right: 10px;
            }
            .coin-label {
              font-size: 14px;
              font-weight: 400;
              margin-right: 10px;
            }
            .coin-cnt {
              font-size: 14px;
              font-weight: 700;

              &.color {
                color: #ff662a;
              }
            }
          }

          //코인구매
          .buy-coin__container {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #bdbdbd;
            position: relative;
            justify-content: space-between;

            .left-group {
              .coin-img {
                width: 20px;
                height: 20px;
                background: url("../images/icon/icon_coin.png");
                background-repeat: no-repeat;
                background-size: 20px 20px;
                text-indent: -9999px;
                display: inline-block;
                margin-right: 10px;
              }
              .coin-label {
                font-size: 14px;
                font-weight: 400;
                margin-right: 10px;
              }
              .coin-cnt {
                font-size: 14px;
                font-weight: 700;
      
                &.color {
                  color: #ff662a;
                }
              }
            }

            .right-group {

              button{
                min-width: 73px;
                padding: 10px;
                color: #fff;
                background: #ff662a;
                border-radius: 6px;
              }
            }
            
          }


          //구매내역
          .my-coin__container {
            display: flex;
            align-items: center;
            padding: 20px 20px;
            border-bottom: 1px solid #bdbdbd;
            position: relative;
            justify-content: space-between;

            .date {
              font-size: 14px;
              font-weight: 400;
              color: #444444;
            }

            .text-group {
              padding-left: 10px;
              font-size: 14px;
              font-weight: 400;
              display : flex;
              justify-content: space-between;
              width: 100px;

              &.pading-left30{
                padding-left: 30px;
              }
              
              .title{
                margin-right: 10px;
                color: #444444;
              }
              .value {
                color: #ff662a;
              }
              
            }


            
          }

           //사용내역
           .use-coin__container {
            display: flex;
            align-items: center;
            padding: 20px 20px;
            border-bottom: 1px solid #bdbdbd;
            position: relative;
            justify-content: space-between;

            .date {
              font-size: 14px;
              font-weight: 400;
              color: #444444;
            }

            .text-group {
              padding-left: 10px;
              font-size: 14px;
              font-weight: 400;
              display : flex;
              justify-content: space-between;
              width: 100px;

              .title{
                margin-right: 10px;
                color: #444444;
              }
              .value {
                color: #ff662a;
              }
              
            }
          }


          .no-data__container {
            font-size: 14px;
            font-weight: 400;
            height: 420px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

        }
      }
      

    }
  }
}
