@charset 'utf-8';

@mixin fully {
  width: 100%;
  height: 100%;
}

@mixin elem-pos($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin button-set($padding-top, $align) {
  padding-top: $padding-top;
  text-align: $align;
}

@mixin inline-button {
  display: inline-block;
  vertical-align: middle;
}

@mixin button-radius-primary {
  border-radius: 3px;
}

@mixin transition($prop...) {
  -webkit-transition: $prop;
  -moz-transition: $prop;
  -ms-transition: $prop;
  -o-transition: $prop;
  transition: $prop;
}

@mixin clfix {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  }
}

@mixin bg($url, $pos) {
  background-image: url($url);
  background-position: $pos;
  background-repeat: no-repeat;
}

@mixin ic-add {
  @include bg('../images/icon/icon_add.png', calc(100% - 8px) 50%);
}
@mixin ic-delete {
  @include bg('../images/icon/icon_x_4.png', 50% 50%);
}
@mixin ic-initial {
  @include bg('../images/icon/icon_re_2.png', 0 50%);
}
@mixin ic-initial-black {
  @include bg('../images/icon/icon_re_black.svg', 0 50%);
}
@mixin ic-search {
  @include bg('../images/icon/icon_large_zoom.svg', 50% 50%);
}
@mixin ic-modal-close {
  @include bg('../images/icon/icon_x_5.png', 50% 50%);
}
@mixin ic-select-outlined-arrow {
  @include bg('../images/icon/icon_select_down.png', calc(100% - 5px) 50%);
}
@mixin ic-close {
  @include bg('../images/icon/icon_x_4.png', 0 50%);
}
