.my-page__container {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  top: 79px;
  left: 0;

  .my-page__user-name {
    display: block;
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    margin-top: 61px;
    margin-bottom: 8px;
  }

  .my-page__user-email {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #979797;
    margin-bottom: 76px;
  }

  //tab menu
  .my-page__tab-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    button {
      display: block;
      text-align: center;
      padding: 0 16px 11px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      box-sizing: border-box;
      text-decoration: none;
      color: #b3b3b3;
      border-bottom: 2px solid #b3b3b3;
      position: relative;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &.on {
        display: block;
        font-weight: 700;
        color: #ff662a;
        border-bottom: 3px solid #ff662a;
      }

      &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-bottom: 2px solid rgba(0, 0, 0, 0.075);
      }
    }
  }

  //탭 별 화면
  .my-page__section {
    // second tab
    .brands-no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      height : 200px;
      color: #333333;
      margin: 40px auto;
      padding-left: 58px;
    }

    .section {
      width: 1280px;
      margin: 0 auto;
      display: none;
      margin-bottom: 65px;

      &:last-child {
        width: 378px;
      }

      &.active {
        display: block;

        //first tab

        // second tab
        .brands-no-data {
          color: red;
        }

        .brand__list-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          
          .item {
            margin: 0 0 50px 48px;
    
            &:nth-of-type(4n + 1) {
              margin-left: 0px;
            }
          }
        }

        // third tab
        .main-group {
          margin-top: 18px;
          
          .group {
            position: relative;

            .input-box {
              display: block;
              width: 100%;
              height: 56px;
              padding: 10px 7px;
              margin: 0 0 21px 0;
              background: #f9f9f9;
              border: 1px solid #747474;
              border-radius: 4px;
              outline: none;
              font-size: 16px;
              font-weight: 400;
              color: #979797;
              box-sizing: border-box;

              &:last-child {
                margin-bottom: 60px;
              }

              &:focus {
                color: #333333;
                border: 2px solid #333333;
              }

              &:focus + .input-title {
                color: #333333;
              }
            }

            .input-title {
              font-size: 12px;
              font-weight: 400;
              color: #b3b3b3;
              position: absolute;
              top: -10px;
              left: 6px;
              padding: 2px;
              background: #fff;
            }
          }
        }

        .sub-title {
          display: block;
          font-size: 14px;
          font-weight: 700;
          color: #333333;
          margin-bottom: 8px;
        }
        .divider {
          border-bottom: 3px solid #dcdfe2;
          margin-bottom: 12px;
        }

        .sub-contents {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }

        .my-page__private {
          margin-bottom: 33px;
          .checkbox {
            &.check-primary {
              span {
                color: #979797;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
          .my-page__inquire-validation {
            color: #ff662a;
            font-size: 12px;
            font-weight: 400;
          }
        }

        .my-page__change-btn {
          margin: 24px 0 60px 0;
        }
        .my-page__save-btn {
          margin: 24px 0 12px 0;
        }
        .my-page__change-btn,
        .my-page__save-btn {
          background-color: #ff662a;
          height: 56px;
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          display: flex;
          width: 100%;
          padding: 16px;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          box-sizing: border-box;
          border-radius: 4px;
          position: relative;

          &:hover::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.075);
          }
          &.inactive {
            background-color: #dcdfe2;
          }
        }

        .delete-user {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          border-bottom: 1px solid #333333;
          margin: 0 auto 160px;
          display: block;
          box-sizing: border-box;

          &:hover {
            color: #747474;
            border-bottom: 1px solid #747474;
          }
        }
      }
    }
  }

  .snackbar-password-change {
    &.success {
      .MuiSnackbarContent-root {
        padding: 10px 20px;
      }

      .MuiSnackbarContent-root {
        background: #0fb553 !important;

        .snackbar-password-change-text {
          margin-right: 10px;
          display: inline-block;
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          text-decoration: none;
          box-sizing: border-box;
        }
      }
      .MuiAlert-standardSuccess {
        background: #0fb553 !important;

        .MuiAlert-icon {
          display: none;
        }
        .MuiAlert-message {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    &.fail {
      .MuiSnackbarContent-root {
        padding: 10px 20px;
      }

      .MuiSnackbarContent-root {
        background: #ff5050 !important;

        .snackbar-password-change-text {
          margin-right: 10px;
          display: inline-block;
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          text-decoration: none;
          box-sizing: border-box;
        }
      }
      .MuiAlert-standardSuccess {
        background: #ff5050 !important;

        .MuiAlert-icon {
          display: none;
        }
        .MuiAlert-message {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}

.password-change__container {
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 378px;
  background: #fff;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  .password-change__title-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    border-bottom: 1px solid #b3b3b3;

    .password-change__title {
      color: #ff662a;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
    }
    .password-change__close-btn {
      background: url('../images/icon/icon_close.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 18px;
      height: 18px;
      display: block;
      text-indent: -9999px;
      cursor: pointer;
    }
  }

  .password-change__content-group {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 30px 20px;

    .password-change__content-content {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 34px;
    }

    .main-group {
      .group {
        position: relative;

        .input-box {
          display: block;
          width: 100%;
          height: 56px;
          padding: 10px 7px;
          margin: 0 0 21px 0;
          background: #f9f9f9;
          border: 1px solid #747474;
          border-radius: 4px;
          outline: none;
          font-size: 16px;
          font-weight: 400;
          color: #979797;
          box-sizing: border-box;

          &:last-child {
            margin-bottom: 60px;
          }

          &:focus {
            color: #333333;
            border: 2px solid #333333;
          }

          &:focus + .input-title {
            color: #333333;
          }
        }

        .input-title {
          font-size: 12px;
          font-weight: 400;
          color: #b3b3b3;
          position: absolute;
          top: -10px;
          left: 6px;
          padding: 2px;
          background: #fff;
        }
      }
    }

    .password-change__content-btn-group {
      display: flex;
    }

    .btn-and-progress-group {
      position: relative;

      .circular-progress-box {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .circular-progress {
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // margin-top: 20px;
          // margin-bottom: 20px;
          // margin-left: -20px;
          color: #fff;
        }
      }
    }

    .password-change__content-btn {
      background-color: #ff662a;
      height: 56px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      display: flex;
      width: 100%;
      padding: 16px;
      margin-bottom: 10px 0;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      cursor: pointer;

      &.grey {
        background: #dcdfe2;
        margin-right: 8px;
      }

      &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.075);
      }
      &.inactive {
        background-color: #dcdfe2;
      }
    }
  }
}

.snackbar {
  padding: 10px 20px !important;
  display: flex !important;
  z-index: 104 !important;

  &.favorite-add {
    background: #0fb553 !important;
  }

  &.favorite-delete {
    background: #ff5050 !important;
  }

  &.success {
    background: #0fb553 !important;
  }
  &.warning {
    background: #ffc850 !important;
  }
  &.error {
    background: #ff5050 !important;
  }

  &:first-child {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    box-sizing: border-box;
  }
  &:last-child {
    .MuiButton-label {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
