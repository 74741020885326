.brand-detail__franchise-inquire-container {
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 378px;
  height:675;
  background: #fff;
  border-radius: 4px;

  .brand-detail__title-group {
    display:flex;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    padding: 20px 20px;
    border-bottom: 1px solid #B3B3B3;

    .brand-detail__title {
      color: #FF662A;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
    }
    .brand-detail__close-btn {
      background: url('../images/icon/icon_close.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width:18px;
      height:18px;
      display:block;
      text-indent: -9999px;
      cursor: pointer;
    }
  }

  .brand-detail__content-group {
    display:flex;
    width: 100%;
    flex-direction: column;
    padding: 30px 20px;
    
    .brand-detail__content-content {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }

    .brand-detail__content-btn {
      background-color: #FF662A;
      height: 56px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      display: flex;
      width: 100%;
      padding: 16px;
      margin: 10px 0;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      cursor: pointer;

      &:hover::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .075);
      }
      &.inactive {
        background-color: #DCDFE2;
      }
    }

    .brand-detail__input {
      margin-top:41px;

      .brand-detail__inquire-name {
        position: relative;
        margin-bottom:21px;

        .brand-detail__inquire-input-box {
          display: block;
          width: 100%;
          height: 56px;
          padding: 10px 7px;
          border: 1px solid #E5E5E5;
          border-radius: 4px;
          outline:none;
          font-size: 16px;
          font-weight: 400;
          color: #747474;
          box-sizing: border-box;

          &:focus{
            color: #333333;
            border: 2px solid #333333;
            ;
          }

          &:focus+.brand-detail__inquire-name-title {
            color: #333333;
          }  
        }

        .brand-detail__inquire-name-title {
          font-size: 12px;
          font-weight: 400;
          color: #747474;
          position: absolute;
          top: -10px;
          left: 6px;
          padding: 2px;
          background: #fff;
        }

        .brand-detail__inquire-validation{
          color: #FF662A;
          font-size: 12px;
          font-weight: 400;

        }
      }
      
    }

    .brand-detail__private {
      margin-bottom: 33px;
      .checkbox {
        &.check-primary {
          span {
            color : #979797;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .brand-detail__inquire-validation{
        color: #FF662A;
        font-size: 12px;
        font-weight: 400;

      }
    }
    

  }
}