.footer {
    background: #eeeeee;
    position: relative;
    z-index: 1;
    top: 79px;


    .footer__inner {
        width: 1280px;
        padding: 63px 0 100px 0;
        box-sizing: border-box;
        background: #eeeeee;
        margin: auto;

        .footer__main-cc-parent {
            display:flex;
            .footer__main-group {

                span {
                    font-size: 16px;
                    font-weight: 500;
                    color: #333333;
                    display: block;
                    margin-bottom:24px;
                }

                .footer__sub-group {
                    display: flex;

                    .footer__address,
                    .footer__representative,
                    .footer__business-number {
                        margin-bottom:0px;

                        &:not(:last-child) {
                            margin-right:40px;
                        }
                        &:last-child {
                            margin-right:145px;
                        }

                        span {
                            display: block;
                            margin-bottom:0;
                        }

                        :first-child {
                            font-size: 14px;
                            font-weight: 400;
                            color: #979797;
                            margin-bottom:8px;
                        }

                        :last-child {
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                }
            }

            .footer__cc-group {
                span {
                    font-size: 16px;
                    font-weight: 500;
                    color: #333333;
                    display: block;

                    &:first-child {
                        margin-bottom:17px;
                        font-size: 16px;
                    }

                }
                a {
                    font-size: 16px;
                    font-weight: 500;
                    color: #333333;
                    display: block;
                    font-size: 28px;
                }
            }
        }

        .footer__divider-group {
            height:1px;
            border-bottom: 1px solid #dfdfdf;
            margin:40px 0;
            box-sizing:border-box;
        }

        .footer__terms-group {

            .footer__terms-info {
                display:flex;
                margin-bottom:22px;

                a, button {
                    display:block;
                    font-size:14px;
                    font-weight:400;
                    color: #333333;
                    margin-right:25px;
                }
            }

            .footer__terms-copyright {
                color: #979797;
                font-size:12px;
                font-weight:400;
                display:block;
            }
        }
    }
}
