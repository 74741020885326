.admin-franchise__container {
  width: 1284px;
  margin: auto;
  padding: 20px;

  //머티리얼 ui 초기화
  [class^='MuiBox-root MuiBox-root-'],
  [class^='MuiTypography-root MuiTypography-body'] {
    padding: 0;
    margin: 0;
  }

  [class^='PrivateTabIndicator-colorPrimary'] {
    background-color: #fff;
  }

  //머티리얼 탭 UI 수정
  .MuiTabs-root {
    .MuiTabs-scroller {
      &.MuiTabs-fixed {
        &.MuiTabs-fixed {
          .PrivateTabIndicator-root-1 {
            &.PrivateTabIndicator-colorPrimary-2 {
              background-color: #fff;
              border-bottom: 4px solid #b3b3b3;
              &.MuiTabs-indicator {
                border-bottom: 4px solid #ff662a;
              }
            }
          }
        }

        .MuiTabs-flexContainer + span {
          background-color: #ff662a;
        }

        .MuiTabs-flexContainer {
          .MuiButtonBase-root {
            &.MuiTab-root {
              &.MuiTab-textColorInherit {
                font-size: 18px;
                font-weight: 400;
                color: #b3b3b3;

                &.Mui-selected {
                  position: relative;
                  .MuiTab-wrapper {
                    color: #ff662a;
                    font-weight: 700;
                  }
                  .MuiTouchRipple-root::before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .list {
    display: none;

    &.active {
      display: block;

      //목록
      .grid-list {
        margin-top: 10px;

        .grid-container {
          .grid-header {
            display: flex;
            align-items: center;
            padding: 10px 0;
            font-size: 14px;
            font-weight: 700;
            color: #333333;
            background: #ebebeb;

            .header-checkbox {
              display: inline-block;
              width: 18px;
              height: 18px;
              border: 2px solid #b1bac3;
              border-radius: 2px;
              background-image: url(../images/icon/icon_checkbox_small_default.png);
              background-position: 50% 50%;
              background-size: 18px 18px;
              background-repeat: no-repeat;

              &:checked {
                background-color: #ff662a;
                border-color: #ff662a;
                background-image: url(../images/icon/icon_checkbox_small_select.png);
                background-position: 50% 50%;
                background-size: contain;
                background-size: 18px 18px;
                background-repeat: no-repeat;
              }
            }
            .name {
              flex: 1;
              text-align: center;
            }
            .area {
              flex: 1;
              text-align: center;
            }
            .brand{
              flex:1;
              text-align: center;
            }
            .contract {
              flex: 1;
              text-align: center;
            }
            .page {
              flex: 1;
              text-align: center;
            }
            .date {
              flex: 1;
              text-align: center;
            }
          }

          .grid-row {
            display: flex;
            align-items: center;
            padding: 10px 0;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            background: #eeeeee;
            cursor: pointer;
            position: relative;

            &:hover::before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.16);
            }

            &:nth-of-type(2n + 0) {
              background: #ffffff;
            }

            .row-checkbox {
              display: inline-block;
              width: 18px;
              height: 18px;
              border: 2px solid #b1bac3;
              border-radius: 2px;
              background-image: url(../images/icon/icon_checkbox_small_default.png);
              background-position: 50% 50%;
              background-size: contain;

              &:checked {
                background-color: #ff662a;
                border-color: #ff662a;
                background-image: url(../images/icon/icon_checkbox_small_select.png);
                background-position: 50% 50%;
                background-size: contain;
              }
            }
            .name {
              flex: 1;
              text-align: center;
            }
            .area {
              flex: 1;
              text-align: center;
            }
            .brand{
              flex:1;
              text-align: center;
            }
            .contract {
              flex: 1;
              text-align: center;
            }
            .page {
              flex: 1;
              text-align: center;
            }
            .date {
              flex: 1;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .detail {
    display: none;

    &.active {
      display: block;

      //디테일
      .admin-franchise-detail__container {
        .title-group {
          font-size: 24px;
          font-weight: 800;
          color: #333333;
          display: block;
          position: relative;
          margin-bottom: 20px;
          box-sizing: border-box;

          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 120%;
            border-bottom: 2px solid #ebebeb;
          }
        }

        .detail-group {
          .field-group {
            .row-group {
              display: flex;
              align-items: center;
              margin: 0 0 10px;

              .title-header {
                width: 140px;
                font-size: 14px;
                font-weight: 700;
                color: #333333;
                padding: 0 20px;
              }
              .select.outlined {
                flex: 2;
                height: 48px;
              }

              .input-box {
                flex: 2;
                display: block;
                height: 48px;
                padding: 10px 7px;
                border: 1px solid #747474;
                border-radius: 4px;
                outline: none;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                box-sizing: border-box;

                &:focus {
                  color: #333333;
                  border: 2px solid #333333;
                }

                &:focus + .login__email-title {
                  color: #333333;
                }
              }
            }
          }

          .added-detail {
            margin: 20px 0;
            padding-left: 20px;

            .added-date {
              display: block;
              font-size: 14px;
              font-weight: 400;
              color: #686868;
            }
            .update-date {
              display: block;
              font-size: 14px;
              font-weight: 400;
              color: #686868;
            }
          }

          .button-group {
            display: flex;
            margin: 32px 0;

            .btn {
              background: #ff662a;
              height: 56px;
              font-size: 16px;
              font-weight: 400;
              color: #fff;
              width: 100%;
              padding: 16px;
              margin: 0 20px;
              text-decoration: none;
              box-sizing: border-box;
              border-radius: 4px;
              position: relative;

              &.btn--cancel {
                background: #fff;
                color: #333333;
                border: 1px solid #dcdfe2;
              }
              &.btn--delete {
                background: #333333;
                color: #fff;
              }
              &.btn--save {
                background: #ff662a;
                color: #fff;
              }

              &:hover::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.075);
              }
              &.inactive {
                background-color: #dcdfe2;
              }
            }
          }
        }
      }
    }
  }
}
