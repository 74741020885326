@import 'mixin';

#naver__map {
  width: 100%;
  height: 100%;

  :nth-child(5){
    padding-top: 20px !important;
    right: 35px !important;
  }
}
.keyword-color {
  color: #ff662a;
}
.naver__address-by-geocode {
  padding: 0 0 20px 20px;
  display: block;
  font-size: 14px;
  font-weight: 700;

  &.inactive {
    display: none;
  }
}

.map__button {
  background-color: #ff662a;
  height: 56px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;

  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.075);
  }

  &.inactive {
    background-color: #dcdfe2;
  }
}

// 1단 레이어
.mapAsideContainer {
  display: none;
  max-width: 378px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  border-right: 1px solid #dcdfe2;
  box-sizing: border-box;

  &.active {
    display: block;

    .search-brand {
      background-color: #fff;
      padding: 32px 20px;
      box-sizing: border-box;
      margin: 0 auto;

      .inner {
        .search {
          position: relative;
          display: block;
          width: 338px;
          height: 46px;
          position: relative;

          input {
            font-size: 14px;
            font-weight: 400;
            width: 100%;
            height: 100%;
            border: 4px solid #ff662a;
            padding: 13px 0 13px 44px;
            box-sizing: border-box;

            &::placeholder {
              color: #979797;
              font-size: 1em;
            }
          }
          .ic-search {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 60px;
            @include ic-search;
            background-position: 36% 66%;
            cursor: auto;
          }

          .map__search-brand-close {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 60px;
            align-items: center;
            padding: 1px 0 0 30px;
            box-sizing: border-box;
            color: #747474;
            font-size: 14px;
            background: url('../images/icon/icon_close.png');
            background-repeat: no-repeat;
            background-size: fit;
            background-position: 56% 66%;
            cursor: pointer;
            text-indent: -9999px;
            display: none;

            &.active {
              display: block;
            }
          }
        }
      }
    }

    .map__background-layer {
      display: none;

      &.backgroundLayerActive {
        display: block;

        $a: 100%;
        $b: 169px;
        height: calc(#{$a}-#{$b});
        width: 100%;

        .map__brand-search-result {
          display: none;

          &.existResult {
            display: block;

            .map__search-no-result {
              display: flex;
              padding: 0px 20px;
              justify-content: center;
              color: #979797;
            }

            .map__brand-search-group {
              display: flex;
              padding: 10px 20px;
              box-sizing: border-box;
              justify-content: space-between;
              align-items: center;
              position: relative;
              cursor: pointer;

              &:hover::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100;
                height: 100;
                background: rgba(0, 0, 0, 0.075);
              }
              div {
                display: flex;
                align-items: center;
                .map__brand-search-thumb-logo {
                  display: flex;
                  align-items: center;
                  width: 48px;
                  height: 48px;
                  border-radius: 4px;
                  margin-right: 12px;
                  box-sizing: border-box;
                  border: 1px solid #dcdfe2;
                  img {
                    width: 100%;
                  }
                }
                .map__brand-search-thumb-gps {
                  margin-right: 12px;
                  border-radius: 4px;
                  box-sizing: border-box;

                  .gps {
                    display: block;
                    box-sizing: border-box;
                    background-image: url('../images/icon/icon_location.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    text-indent: -9999px;
                    width: 12px;
                    height: 12px;
                    background-position: 50% 50%;
                  }
                }
                .map__brand-search-name {
                  font-size: 14px;
                  font-weight: 500;
                  .keyword-color {
                    color: #ff662a;
                  }
                }
              }

              .map__brand-search-category-name {
                color: #979797;
                font-size: 14px;
              }
            }

            .divider {
              height: 1px;
              border-bottom: 1px solid #eeeeee;
            }
          }
        }
      }
    }

    .map__brand-list {
      &.inactive {
        display: none;
      }

      .map__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;
        margin: 0 20px;
        border-bottom: 3px solid #333333;
        box-sizing: border-box;

        span {
          font-size: 14px;
          font-weight: 700;
          color: #333333;
          line-height: 1.4;
        }

        .map__order-group {
          display: flex;
          align-items: center;

          .map__order-group-select-box {
            color: #979797;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            border: none;
            background: url('../images/icon/icon_check_down.png') no-repeat 100%
              50%;
            padding-right: 16px;
            box-sizing: border-box;

            &::-ms-expand {
              display: none;
            }
            &:focus {
              border-color: #fff;
              outline: none;
            }
          }

          button {
            font-size: 14px;
            font-weight: 400;
            color: #979797;
            line-height: 1.4;
            display: block;
            padding: 0 16px 0 0;
            box-sizing: border-box;
            background-image: url('../images/icon/icon_check_down.png');
            background-position: 100% calc(50% - 0px);
            background-repeat: no-repeat;
          }
        }
      }
    }

    .map__list-container {
      &.inactive {
        display: none;
      }

      &.exist-recruiting {
        padding-bottom: 170px;
      }

      .map__list-item-container {
        .map__list-no-data {
          display:flex;
          padding: 40px 0;
          justify-content: center;
        }
        .map__list-item {
          padding: 20px 20px;
          display: flex;
          position: relative;
          box-sizing: border-box;

          &.checked {
            background: #f5f5f5;
          }

          &:hover {
            background: #f7f8fa;
            cursor: pointer;

            .map__brand-name {
              color: #ff662a;
            }
          }

          .map__brand_img__container{
            width: 48px;
            height: 48px;
            margin-right: 12px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            border: 1px solid #dcdfe2;

            .map__brand_img {
              width: 100%;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              text-indent: -9999px;
            }
          }

          .map__item-right {
            flex: 1;

            .map__info-and-event {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .map__brand_info {
                display: flex;

                span {
                  font-size: 12px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 1.3;
                  display: block;
                  margin-right: 4px;

                  :nth-of-type(3) {
                    font-weight: 500;
                    margin-right: 0;
                  }
                }

                .map__brand_category {
                  color: #ff662a;
                  display: block;
                  margin-right: 8px;
                }
              }

              .util {
                display: flex;
                align-items: center;
                justify-content: space-between;

                label {
                  margin-left: 10px;
                  margin-right: 10px;

                  &:first-child {
                    display: flex;
                    margin-left: 0;
                  }
                }

                .comparison {
                  display: inline-block;
                  width: 18px;
                  height: 18px;
                  border: 0px solid #b1bac3;
                  border-radius: 0px;
                  background-image: url(../images/icon/icon_checkbox_small_default.svg);
                  background-position: 50% 50%;
                  background-repeat: no-repeat;
                  background-size: contain;
                  margin-right: 4px;
                  &.on {
                    display: inline-block;
                    background-color: #ff662a;
                    border-color: #ff662a;
                    border: 2px solid #ff662a;
                    border-radius: 6px;
                    background-image: url(../images/icon/icon_checkbox_small_select.png);
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: contain;
                  }
                }
                .comparison + span {
                  color: #979797;
                  font-size: 12px;
                  font-weight: 400;
                }

                .favorite {
                  display: inline-block;
                  width: 18px;
                  height: 18px;
                  border: 0px solid #b1bac3;
                  border-radius: 0px;
                  box-sizing: border-box;
                  background-image: url(../images/icon/icon_like_default.svg);
                  background-position: 50% 50%;
                  background-size: contain;
                  background-repeat: no-repeat;

                  &.on {
                    display: inline-block;
                    background-image: url(../images/icon/icon_like_select.svg);
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: contain;
                  }
                }

                .check-show-hide {
                  display: inline-block;
                  width: 18px;
                  height: 18px;
                  border: 0;
                  background-image: url(../images/icon/icon_eye_default.png);
                  background-position: 50% 50%;
                  background-size: contain;
                  background-repeat: no-repeat;

                  &.on {
                    display: inline-block;
                    border: 0;
                    background-image: url(../images/icon/icon_eye_default.png);
                    filter: sepia(70%) brightness(70%);
                  }
                }
              }
            }

            .map__brand-name {
              display: block;
              font-size: 16px;
              font-weight: 400;
              line-height: 1.4;
            }
          }
        }
      }
    }

    .map__franchise-recruit {
      display: none;
      width: 378px;
      padding: 20px 20px 31px 20px;
      border-top: 1px solid #b4d8c2;
      border-right: 1px solid #dcdfe2;
      box-sizing: border-box;
      background: #f4faf7;
      position: fixed;
      left: 0;
      bottom: 0;

      &.active {
        display: block;

        .arrow-container {
          display: none;
        }

        .slick-slider {
          &.slick-initialized {
            .slick-dots {
              top: -9px;
              right: 0;
              width: 80px;
              height: 30px;
              text-align: right;

              .paging-container {
                .slick-active {
                  background: #ff662a;
                  border-radius: 50%;
                  box-sizing: border-box;
                }
              }
            }
          }
        }

        .map__franchise-title-group {
          margin: 0 0 24px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
          }

          div {
            display: flex;

            i {
              display: block;
              width: 12px;
              height: 12px;
              background: #dcdfe2;
              border-radius: 10px;
              box-sizing: border-box;
              margin-left: 4px;
              cursor: pointer;

              &.on {
                background: #ff662a;
              }
            }
          }
        }

        .map__franchise-brand-group {
          box-sizing: border-box;

          .map__list-item {
            display: flex;
            position: relative;
            box-sizing: border-box;

            .map__brand_img__container{
              width: 48px;
              height: 48px;
              margin-right: 12px;
              border-radius: 6px;
              display: flex;
              align-items: center;
              border: 1px solid #dcdfe2;


            }
            .map__brand_img {
              width: 48px;
              height: 48px;
              margin-right: 12px;
              border-radius: 6px;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              text-indent: -9999px;
            }

            .map__item-right {
              flex: 1;

              .map__info-and-event {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .map__brand_info {
                  display: flex;

                  span {
                    font-size: 12px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 1.3;
                    display: block;
                    margin-right: 4px;

                    :nth-of-type(3) {
                      font-weight: 500;
                      margin-right: 0;
                    }
                  }

                  .map__brand_category {
                    color: #ff662a;
                    display: block;
                    margin-right: 8px;
                  }

                  .map__item_location {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;
                    overflow: hidden;

                    i {
                      background: url('../images/icon/icon_location.svg');
                      background-repeat: no-repeat;
                      background-position: 50% 50%;
                      background-size: contain;
                      display: block;
                      width: 12px;
                      height: 12px;
                      text-indent: -9999px;
                      margin-right: 4px;
                    }

                    .map__branch_area {
                      font-size: 12px;
                      font-weight: 300;
                      overflow: hidden;
                      color: #333333;
                    }
                  }
                }

                .util {
                  display: flex;
                  align-items: center;

                  label {
                    margin-left: 10px;
                    display: flex;
                    margin-right: 10px;
                    &:first-child {
                      margin-left: 0;

                      display: flex;
                    }
                  }

                  .comparison {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border: 0px solid #b1bac3;
                    background-image: url(../images/icon/icon_checkbox_small_default.svg);
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 4px;
                    &.on {
                      display: inline-block;
                      background-color: #ff662a;
                      border-color: #ff662a;
                      border: 2px solid #ff662a;
                      border-radius: 6px;
                      background-image: url(../images/icon/icon_checkbox_small_select.png);
                      background-position: 50% 50%;
                      background-repeat: no-repeat;
                      background-size: contain;
                    }
                  }
                  .comparison + span {
                    color: #979797;
                    font-size: 12px;
                    font-weight: 400;
                  }

                  .favorite {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border: 0;
                    background-image: url(../images/icon/icon_like_default@2x.png);
                    background-position: 50% 50%;
                    background-size: contain;

                    &.on {
                      display: inline-block;
                      border: 0;
                      background-image: url(../images/icon/icon_like_select@2x.png);
                    }
                  }
                }
              }

              .map__brand-name {
                display: block;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.4;
                color: #ff662a;
              }
            }
          }

          .map__brand-intro-ask {
            display: flex;
            margin-left: 60px;
            margin-top: 8px;

            a {
              font-size: 12px;

              .map__brand-intro {
                font-size: 12px;
                color: #333333;
                text-decoration: underline;
                box-sizing: border-box;
                margin-right: 20px;
                cursor: pointer;
              }
            }

            .map__brand-ask {
              font-size: 12px;
              color: #333333;
              text-decoration: underline;
              box-sizing: border-box;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

// 2단 레이어
.mapAsideContainer-2dep {
  display: none;
  max-width: 378px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  position: relative;
  border-right: 1px solid #dcdfe2;
  box-sizing: border-box;

  &.active {
    display: block;

    .map__branch-list {
      .map__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;
        margin: 32px 21px 0px;
        border-bottom: 3px solid #333333;
        box-sizing: border-box;

        span {
          font-size: 14px;
          font-weight: 700;
          color: #333333;
          line-height: 1.4;
        }

        .map__order-group {
          display: flex;
          align-items: center;

          .map__order-group-select-box {
            color: #979797;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            border: none;
            background: url('../images/icon/icon_check_down.png') no-repeat 100%
              50%;
            padding-right: 16px;
            box-sizing: border-box;

            &::-ms-expand {
              display: none;
            }
            &:focus {
              border-color: #fff;
              outline: none;
            }

            &.no-icon {
              margin-right: 20px;
            }
          }
        }
      }
    }

    .map__list-container {
      .map__list-item-container {
        position: relative;
        box-sizing: border-box;

        .map__list-item {
          padding: 20px;

          &.checked {
            background: #f5f5f5;
          }

          &:hover {
            background: #f7f8fa;
            cursor: pointer;

            .map__branch_name {
              color: #ff662a;
            }
          }

          .map__item_location {
            display: flex;
            align-items: center;
            margin-bottom: 4px;

            i {
              background: url('../images/icon/icon_location.svg');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: 50% 50%;
              display: block;
              width: 12px;
              height: 12px;
              text-indent: -9999px;
              margin-right: 4px;
            }

            .map__branch_area {
              font-size: 12px;
              font-weight: 300;
              color: #333333;
            }
          }

          .map__branch_name {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }
}

// 3단 레이어
.mapAsideContainer-3dep {
  display: none;
  width: 378px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f7f8fa;
  position: relative;
  border-right: 1px solid #dcdfe2;
  box-sizing: border-box;

  &.active {
    display: block;

    .map__branch-info {
      height: auto;
      background-color: #f7f8fa;
      padding: 40px 20px 60px;
      box-sizing: border-box;
      position: relative;
      overflow-x: visible;

      .map__branch-logo__container{

        width: 48px;
        height: 48px;
        border-radius: 6px;
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #dcdfe2;

        .map__branch-logo {
          width: 100%;
          text-indent: -9999px;
          display: block;
          background-repeat: no-repeat;
          background-size: 48px 48px;
          box-sizing: border-box;
        }
      }

      .map__tab-group {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        button {
          display: block;
          text-align: center;
          padding: 0 16px 11px;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.5;
          box-sizing: border-box;
          text-decoration: none;
          color: #b3b3b3;
          border-bottom: 2px solid #b3b3b3;
          position: relative;

          &:first-child {
            margin-right: 20px;
          }

          &.on {
            display: block;
            font-weight: 700;
            color: #ff662a;
            border-bottom: 3px solid #ff662a;
          }

          &:hover::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-bottom: 2px solid rgba(0, 0, 0, 0.075);
          }
        }
      }

      .map__barnch-detail {
        span {
          display: block;

          &:nth-of-type(1) {
            font-size: 12px;
            font-weight: 400;
            color: #ff662a;
          }

          &:nth-of-type(2) {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 8px;
          }
        }

        div {
          font-size: 14px;
          font-weight: 400;
          color: #979797;
          margin-bottom: 20px;
        }
      }

      .map__badge-theme {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 32px;

        .map__badge-image,
        .map__theme-image {
          padding: 2px 8px 4px;
          color: #fff;
          background: #ff662a;
          border-radius: 2px;
          box-sizing: border-box;
          text-align: center;
          margin: 0 4px 4px 0px;
        }
      }

      .map__branch-costs {
        border-top: 1px solid #eeeeee;
        margin-bottom: 32px;
        box-sizing: border-box;

        .map__start-up-cost,
        .map__monthly_sales,
        .map__monthly_sales33,
        .map__branch-total-cnt {
          padding: 12px 0;
          border-bottom: 1px solid #eeeeee;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;

          span {
            font-size: 12px;
            color: #747474;
          }

          div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            span {
              display: block;

              &:nth-of-type(1) {
                font-size: 14px;
                font-weight: 500;
                color: #ff662a;
              }

              &:nth-of-type(2) {
                font-size: 12px;
                color: #b3b3b3;
              }
            }

            .detail {
              font-size: 12px;
              color: #b3b3b3;
              margin-top: 8px;
            }
          }
        }
      }

      .map__button-check-group {
        display: flex;
        margin-bottom: 12px;
      }

      .map__button--brand-intro {
        margin-bottom: 12px;
      }

      .map__button {
        &.map__button--sales-check {
          // width: 50%;
          background-color: #fff;
          border: 1px solid #979797;
          border-right: 1px solid #979797;
          // border-radius: 6px 0 0 6px;
          color: #747474;
          //----위 2개 주석 아래 1줄 추가 21.11.06 james
          border-radius: 6px;
        }

        &.map__button--share-check {
          width: 50%;
          background: url('../images/icon/icon_share.png');
          background-repeat: no-repeat;
          background-size: fit;
          background-color: #fff;
          background-position: 38% 54%;
          border: 1px solid #979797;
          border-left: 0px solid #979797;
          border-radius: 0 6px 6px 0;
          color: #747474;
          padding: 0 0 0 26px;
          //----아래 1줄 추가 21.11.06 james
          display: none;
        }

        &.map__button--brand-intro {
          background-color: #fff;
          border: 2px solid #ff662a;
          color: #ff662a;
        }

        &.map__button--franchise-application {
        }
      }
    }
  }
}

//3단 맵 브랜치 정보 팝업
.map__branch-info-popup {
  display: none;
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 368px;
  height: 321px;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;

  &.active {
    display: block;

    .map__branch__title-group {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px;
      border-bottom: 1px solid #b3b3b3;

      .map__branch__title {
        color: #ff662a;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.4;
      }
      .map__branch__close-btn {
        background: url('../images/icon/icon_close.svg');
        background-repeat: no-repeat;
        background-size: 18px 18px;
        width: 18px;
        height: 18px;
        display: block;
        text-indent: -9999px;
        cursor: pointer;
      }
    }

    .map__branch__content-group {
      border-top: 1px solid #eeeeee;
      margin-bottom: 32px;
      box-sizing: border-box;
      padding: 20px 20px;

      .map__branch-monthly-sales,
      .map__branch-monthly_sales33 {
        padding: 12px 0;
        border-bottom: 1px solid #eeeeee;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 12px;
          color: #747474;
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          span {
            display: block;

            &:nth-of-type(1) {
              font-size: 14px;
              font-weight: 500;
              color: #ff662a;
            }

            &:nth-of-type(2) {
              font-size: 12px;
              color: #b3b3b3;
            }
          }
        }
      }
    }
  }
}

//2단접기
.map__2dep-folding-arrow {
  position: absolute;
  top: -2px;
  right: -29px;
  background: rgb(248, 248, 248);
  border: 1px solid #dcdfe2;
  border-radius: 0 4px 4px 0;
  box-sizing: border-box;
  padding: 10px 4px;
  display: none;

  &.active {
    display: block;
    .map__2dep-btn-close {
      background: url('../images/icon/icon_x_4.png');
      background-position: 50% 50%;
      background-repeat: no-repeat;
      text-indent: -9999px;
      width: 20px;
      height: 22px;
    }
  }
  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.075);
  }
}

//3단접기
.map__3dep-folding-arrow {
  position: absolute;
  top: -2px;
  right: -30px;
  background: rgb(248, 248, 248);
  border: 1px solid #dcdfe2;
  border-radius: 0 4px 4px 0;
  box-sizing: border-box;
  padding: 10px 4px;
  display: none;

  &.active {
    display: block;
    .map__3dep-btn-close {
      background: url('../images/icon/icon_x_4.png');
      background-position: 50% 50%;
      background-repeat: no-repeat;
      text-indent: -9999px;
      width: 20px;
      height: 22px;
    }
  }
  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.075);
  }
}

//전체 접기
.map__folding-arrow {
  position: absolute;
  top: 50%;
  right: -19px;
  width: 20px;
  background: rgb(248, 248, 248);
  border: 1px solid #dcdfe2;
  border-radius: 0 4px 4px 0;
  box-sizing: border-box;
  padding: 10px 4px;

  .btn--arrow-left {
    background: url('../images/icon/icon_arrow_large_left_default.png');
    background-size: fit;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }

  .btn--arrow-right {
    background: url('../images/icon/icon_arrow_large_right_default.png');
    background-size: fit;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }

  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.075);
  }
}

#map__order-group-select-box-container {
  display: block;
  padding: 14px 0;
  border-radius: 6px;
  box-shadow: 0 0 2px #dcdfe2;
  box-sizing: border-box;

  .map__order-group-select-box-item {
    display: block;
    padding: 14px;
    box-sizing: border-box;

    &.active {
      background: #d1d1d1;
    }
    &:hover {
      background: #f1f1f1;
      cursor: pointer;
    }
  }
}



//매출 예측
.selected-brand-for-sales {
  position: fixed;
  right: 19px;
  bottom: 240px;
  z-index: 103;
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  padding:5px;
  font-size:14px;
  font-weight:300;

  img{
    margin-right:4px;
    max-width: 40px;
    max-height: 40px;
    object-fit: contain;

  }

  div {
    div:nth-of-type(1) {
      color:#ff662a;
    }
  }

  .selected-brand-for-sales__close-btn {
    position: absolute;
    right:-10px;
    top: -10px;
    background-image: url("../images/icon/icon_close.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    padding: 8px;
    border-radius: 2px;
    box-sizing: border-box;
    text-indent: -9999px;
    cursor: pointer;
  }
}
