@import 'mixin';
// @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500,700|Open+Sans:300,400,700|Roboto:300,400,500,700,900&display=swap');
// Roboto
// font-weight: 300 -Light;
// font-weight: 400 -Regular;
// font-weight: 500 -Midium;
// font-weight: 700 -Bold;
// font-weight: 900 -Black;

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);
// 'Open Sans'
// 300 -Open Sans Light
// 400 -Open Sans Regular
// 700 -Open Sans Bold

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  src: local('Noto Sans Thin'), local('Noto Sans Thin'), local('Noto Sans Thin');
  src: url(../fonts/NotoSans-Thin.eot);
  src: url(../fonts/NotoSans-Thin.eot?#iefix) format('embedded-opentype'), url(../fonts/NotoSans-Thin.woff) format('woff'),
    url(../fonts/NotoSans-Thin.otf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Noto Sans Light'), local('Noto Sans Light'), local('Noto Sans Light');
  src: url(../fonts/NotoSans-Light.eot);
  src: url(../fonts/NotoSans-Light.eot?#iefix) format('embedded-opentype'), url(../fonts/NotoSans-Light.woff) format('woff'),
    url(../fonts/NotoSans-Light.otf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Regular'), local('Noto Sans Regular'), local('Noto Sans Regular');
  src: url(../fonts/NotoSans-Regular.eot);
  src: url(../fonts/NotoSans-Regular.eot?#iefix) format('embedded-opentype'), url(../fonts/NotoSans-Regular.woff) format('woff'),
    url(../fonts/NotoSans-Regular.otf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans Medium'), local('Noto Sans Medium'), local('Noto Sans Medium');
  src: url(../fonts/NotoSans-Medium.eot);
  src: url(../fonts/NotoSans-Medium.eot?#iefix) format('embedded-opentype'), url(../fonts/NotoSans-Medium.woff) format('woff'),
    url(../fonts/NotoSans-Medium.otf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans Bold'), local('Noto Sans Bold'), local('Noto Sans Bold');
  src: url(../fonts/NotoSans-Bold.eot);
  src: url(../fonts/NotoSans-Bold.eot?#iefix) format('embedded-opentype'), url(../fonts/NotoSans-Bold.woff) format('woff'),
    url(../fonts/NotoSans-Bold.otf) format('truetype');
}
// NotoSans
// font-weight: 100 -Thin;
// font-weight: 300 -Light;
// font-weight: 400 -Regular;
// font-weight: 500 -Medium;
// font-weight: 700 -Bold;
