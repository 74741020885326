.admin-brand__container {
  width: 1284px;
  margin: auto;
  padding: 20px;

  //머티리얼 ui 초기화
  [class^="MuiBox-root MuiBox-root-"],
  [class^="MuiTypography-root MuiTypography-body"] {
    padding: 0;
    margin: 0;
  }
  
  [class^="PrivateTabIndicator-colorPrimary"] {
    background-color: #fff;
  }
  
  //머티리얼 탭 UI 수정
  .MuiTabs-root {
    
    .MuiTabs-scroller {
      &.MuiTabs-fixed {

        &.MuiTabs-fixed{

          .PrivateTabIndicator-root-1 {
            &.PrivateTabIndicator-colorPrimary-2 {
              background-color: #fff;
              border-bottom: 4px solid #B3B3B3;
              &.MuiTabs-indicator{
                border-bottom: 4px solid #FF662A;
              }
            }
          }
        }

        .MuiTabs-flexContainer + span {
          background-color: #FF662A;
        }

        .MuiTabs-flexContainer {
          .MuiButtonBase-root {
            &.MuiTab-root {
              
                
              &.MuiTab-textColorInherit { 
                font-size: 18px;
                font-weight: 400;  
                color: #B3B3B3;

                &.Mui-selected {
                  position: relative; 
                  .MuiTab-wrapper {
                    color: #FF662A;  
                    font-weight: 700;     
                  }
                  .MuiTouchRipple-root::before{
                    content: "";
                    display:block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
  
                  }
                }
              }
            }        
          }
        }
      }
    }
  }

  //브랜드관리 - 가맹점 관리 탭
  .general-tab__container {
      width: 400px;
      margin: 24px auto;

      .main-group {
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
          }

          >.title {
              display: block;
              font-size: 16px;
              font-weight: 700;
              color: #333333;
              margin-bottom: 12px;
              padding-bottom: 4px;
              border-bottom: 1px solid #e1e1e1;
              box-sizing: border-box;
          }
          .detail {
              display: block;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              margin-bottom: 12px;
          }

          .display-image__container {
            display:flex;
            align-items: center;
            justify-content: center;
            width: 400px;
            height: 200px;
            background-color:#fff;

            &.logo {
              width: 48px;
              height: 48px;
              margin: 0 auto;
              border: 1px solid #DCDFE2;
              border-radius: 4px;
              overflow: hidden;

              .logo {
                width: 100%;
              }
            }
            
            &.main {
              width: 276px;
              height: 183px;
              margin: 0 auto;
              //border: 1px solid #DCDFE2;
              box-sizing: border-box;
              border-radius: 4px;
              overflow: hidden;

              .main {
                width: 100%;
              }
            }
            
          }
          
          
          .logo-btn {
            background-color: #FF662A;
            height: 56px;
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            display: flex;
            width: 100%;
            padding: 16px;
            margin: 24px 0 21px 0;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            box-sizing: border-box;
            border-radius: 4px;
            position: relative;
    
            &:hover::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .075);
            }
          }

          .selected-file-group {

          }

          .field {
              position: relative;
    
              .mgr20 {
                margin-right: 20px;
              }
              .input-box {
                display: block;
                width: 100%;
                height: 56px;
                padding: 10px 20px 10px 7px;
                margin: 0 0 21px 0;
                border: 1px solid #747474;
                border-radius: 4px;
                outline:none;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                box-sizing: border-box;

                  &::-webkit-input-placeholder { color: #b3b3b3; font-size: 14px; }
                  &:-ms-input-placeholder      { color: #b3b3b3; font-size: 14px; }
                  &::-moz-placeholder          { color: #b3b3b3; font-size: 14px; }
      
    
                &:focus{
                  color: #333333;
                  border: 2px solid #333333;
                  ;
                }
    
                &:focus+.title {
                  color: #333333;
                }  
              }
    
              .title {
                font-size: 12px;
                font-weight: 400;
                color: #747474;
                position: absolute;
                top: -10px;
                left: 6px;
                padding: 2px;
                backdrop-filter: blur(10px);
                background: #fff;
              }

              .inner-unit {

                font-size: 12px;
                font-weight: 400;
                color: #747474;
                position: absolute;
                top: 20px;
                right: 6px;
                padding: 2px;
              }
            }
      }

      .save-btn {
        background-color: #FF662A;
        height: 56px;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        display: flex;
        width: 100%;
        padding: 16px;
        margin: 24px 0 21px 0;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;

        &:hover::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .075);
        }
    }
  }



  //브랜드관리 - 가맹사업 현황/본사 정보 탭
  .franchise-tab__container {
    padding: 10px;      

    .Mui-expanded {
      background:#eeeeee;
    }

    
    .title-group{
      display: flex;
      align-items: center;
      .title {
        display: block;
        font-size: 16px;
        font-weight: 700;
        color: #333333;
        box-sizing: border-box;
        margin-right: 10px;
      }
      .icon{
        display: block;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        background-color: #aeaeff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 0 4px;
        line-height:1.7;
      }
    }

    .detail-group {
      width: 100%;
      .details {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 12px;
      }
      .data {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
      .data {
        margin-bottom: 12px;
      }

      .comment {
        display: flex;
        textarea {
          background: #fff;
          overflow-y: auto;
          flex: 1;

          &[disabled] {
            background: #c0c0c0;
            color: #fff;
          }
        }

        button {
          width: 100px;
          height: 100px;
          background: #FF662A;
          color: #fff;
        }

      }
    }
    
  }


  //브랜드관리 - 브랜드 정보 탭
  .info-tab__container {
    display: flex;
    padding: 10px;   
    height: calc(100vh - 180px);

    .left-group{
      width: 200px;
      padding: 0 24px 24px;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #eeeeee;

      .inner-main {
        flex: 1;
        overflow: auto; 

        .custom-section-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 8px;
          margin-bottom: 8px;
          border: 1px solid #FF662A;
          border-radius: 4px;
          color: #FF662A;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          cursor: pointer;
          position: relative;
          box-sizing:border-box;

          &:hover::before {
            content: "";
            position: absolute;
            top:0;
            right:0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.16);
            border-radius: 4px;
            box-sizing:border-box;
          }

          &.selected {
            background: #FF662A;
            color: #ffffff;
          }
        }
      }
      .inner-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 8px;
        background:#FF662A;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        cursor: pointer;
        position: relative;
        box-sizing:border-box;

        &:hover::before {
          content: "";
          position: absolute;
          top:0;
          right:0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.16);
          border-radius: 4px;
          box-sizing:border-box;
        }
      }
    }
    .right-group {
      flex: 1;
      padding: 0 24px 24px;

      .header-group {
        box-sizing: border-box;
        border-bottom: 2px solid #FF662A;
        display: flex;
        align-items: center;
        padding-bottom: 4px;

        .title {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.4;
          color: #333333;
          flex:1;
        }
        .button-group {
          display: flex;

          .modify-btn,
          .delete-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 12px;
            background:#FF662A;
            border-radius: 4px;
            color: #FFf;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
            cursor: pointer;
            position: relative;
            padding: 8px 14px;
            box-sizing:border-box;

            &.mg8 {
              margin-right: 8px;
            }
  
            &:hover::before {
              content: "";
              position: absolute;
              top:0;
              right:0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,.16);
              border-radius: 4px;
              box-sizing:border-box;
            }
          }
        }
      }

      .main-group{
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;
        color: #333333;
      }
    }
  }
}




//섹션 추가 팝업
.admin-brand-add-section__container {
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 378px;
  background: #fff;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  .admin-brand-add-section__title-group {
    display:flex;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    padding: 20px 20px;
    border-bottom: 1px solid #B3B3B3;

    .admin-brand-add-section__title {
      color: #FF662A;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
    }
    .admin-brand-add-section__close-btn {
      background: url('../images/icon/icon_close.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width:18px;
      height:18px;
      display:block;
      text-indent: -9999px;
      cursor: pointer;
    }
  }

  .admin-brand-add-section__content-group {
    display:flex;
    width: 100%;
    flex-direction: column;
    padding: 30px 20px;
    
    .admin-brand-add-section__content-content {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 24px;
    }

    .admin-brand-add-section__input-email {
      display: block;
      width: 100%;
      height: 56px;
      padding: 10px 7px;
      margin-bottom: 30px;
      border: 1px solid #747474;
      border-radius: 4px;
      outline:none;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      box-sizing: border-box;

      &:focus{
        color: #333333;
        border: 2px solid #333333;
        ;
      }

      &::-webkit-input-placeholder { color: #b3b3b3; }
      &:-ms-input-placeholder      { color: #b3b3b3; }
      &::-moz-placeholder          { color: #b3b3b3; }
    }

    .admin-brand-add-section__content-btn-group{
      display: flex;
      align-items: center;

      .admin-brand-add-section__content-btn {
        background-color: #FF662A;
        height: 56px;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        display: flex;
        width: 100%;
        padding: 16px;
        margin-bottom: 10px 0;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
  
        &:nth-of-type(1) {
          margin-right: 12px;
          background-color: #747474;
        }
        
        &:hover::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .075);
        }
        &.inactive {
          background-color: #DCDFE2;
        }
      }
    }
  }
}

.ck-editor__main,
.ckeditor-root {
  h1 {
    font-size: 26px;
    font-weight: 700;
    font-family: 'Noto Sans';
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Noto Sans';
  }
  h3 {
    font-size: 22px;
    font-weight: 700;
    font-family: 'Noto Sans';
  }
  h4 {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Noto Sans';
  }
  h5 {
    font-size: 18px;
    font-weight: 700;
    font-family: 'Noto Sans';
  }
  h6 {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Noto Sans';
  }
  p {
    margin: 14px 0;
  }
  a {
    text-decoration: underline;
  }
  i {
    font-style:italic;
  }
  ul {
    list-style-type: disc;
    padding-left: 40px;
  }
  ol {
    list-style-type: decimal;
    padding-left: 40px;
  }
  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
  }
  figure.media {
    max-width: 100%;
    clear: both;
    margin: .9em 0;
    display: block;
    min-width: 15em;
    
    .ck-media__wrapper {
      margin: 0 auto;
    }
  }
  figure.image {
    display: inline-block;
    border: 1px solid gray;
    margin: 0 2px 0 1px;
    background: #f5f2f0;
  }
  
  figure.align-left {
    float: left;
  }
  
  figure.align-right {
    float: right;
  }
  
  figure.image img {
    margin: 8px 8px 0 8px;
  }
  
  figure.image figcaption {
    margin: 6px 8px 6px 8px;
    text-align: center;
  }

  table {
    margin: .9em auto;
    display: table;

    caption {
      background-color: #e2e2e2;
      border: 1px solid #eeeeee;
      padding: 6.4px;
    }
    
    thead {

    }
    tbody {

    }
    tr {
      border:1px solid #eeeeee;
    }
    th {
      min-width: 2em;
      padding: .4em;
      border: 1px solid #bfbfbf;
      font-weight: 700;
      background: #eeeeee;
    }
    td {
      min-width: 2em;
      padding: .4em;
      border: 1px solid #bfbfbf;
    }
  }
    

  
}