.primary {
  height: 4px !important;
  color: #ff662a !important;
}

.MuiSlider-rail {
  height: 4px !important;
  color: #e7e9ec !important;
}
.MuiSlider-track {
  height: 4px !important;
}
.MuiSlider-mark {
  width: 1px !important;
  height: 8px !important;
  top: 25px !important;
  background-color: #e4e4e4 !important;
}
.MuiSlider-markLabel {
  top: 33px !important;
  color: #747474 !important;
  font-family: "Noto Sans", "Open Sans", "Nanum Gothic", "Dotum", sans-serif !important;
  font-size: 0.7em !important;
  font-weight: 300 !important;
}
