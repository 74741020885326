.branch-management-right-group {
  flex: 1;
  padding: 0 0 24px;

  .main-group{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    color: #333333;
    padding: 20px 0 20px;
    box-sizing: border-box;
    display: none;

    &.show {
      display:block;
    }

    .row-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .calc-btn {
        background-color: #FF662A;
        color: #fff;
        font-size: 16px;
        width: 60px;
        height: 40px;
        border-radius: 4px;
        margin-bottom: 21px;
        box-sizing: border-box;
        position: relative;

        &:hover::before {
          content: "";
          position: absolute;
          top:0;
          right:0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.16);
          border-radius: 4px;
          box-sizing:border-box;
        }
      }
    }

    .address_container {
      display: flex;
      align-items: center;
      margin: 0 0 21px 0;

      .calc-radio {
        margin-right : 10px;
        border: 1px solid #747474;
        padding: 0 0 0 8px;
        border-radius: 4px;
        
        &.height56 {
          display: flex;
          align-items: center;
          height: 56px;
          width: 80px;
        }
        &.height135 {
          display: flex;
          align-items: center;
          height: 135px;
          width: 80px;
        }
      }
      

      .address_radio{
        background-color: #FFF;
        margin-left: 16px;
        border: 1px solid rgb(216, 216, 216);
        width: 14px;
        height: 14px;
        border-radius: 100%;

        &:checked{ 
          background-color: #FF662A;
          margin-left: 16px;
          border: none;
          width: 14px;
          height: 14px;
          border-radius: 100%;
        }
      }

      .address_group {
        flex : 9;
        display: block;
      }
    }

    .field {
      position: relative;
      width: 480px;

      &.w440 {
        width: 440px;
      }

      &.full-width {
        width: 100%;
      }
      &.fourth {
        width: 240px;
      }

      .input-box {
        display: flex;
        align-items: center;
        width: 100%;
        height: 56px;
        padding: 10px 7px;
        margin: 0 0 21px 0;
        border: 1px solid #747474;
        border-radius: 4px;
        outline:none;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        box-sizing: border-box;

        &.no-margin-bottom {
          margin-bottom: 0;
        }

        &::-webkit-input-placeholder { color: #b3b3b3; font-size: 14px; }
        &:-ms-input-placeholder      { color: #b3b3b3; font-size: 14px; }
        &::-moz-placeholder          { color: #b3b3b3; font-size: 14px; }


        &:focus{
          color: #333333;
          border: 2px solid #333333;
          box-sizing: border-box;
        }

        &:focus+.title {
          color: #333333;
        }  

        &.disabled {
          background: rgb(242, 242, 242);
        }
      }

      .title {
        font-size: 12px;
        font-weight: 400;
        color: #747474;
        position: absolute;
        top: -10px;
        left: 6px;
        padding: 2px;
        backdrop-filter: blur(10px);
        background: #fff;

        &.disabled {
          //background: transparent;
          border-radius: 4px;
        }
      }
    }
  }

  
}


.branch-management-popup {
  overflow: auto;
  padding: 40px 0;
}

//지점 추가 팝업
.admin-branch-add-branch__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  width: 1014px;
  height: 750px;
  background: #fff;
  border-radius: 4px;
  margin: auto;

  &:focus {
    outline: none;
  }

  .admin-branch-add-branch__title-group {
    display:flex;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    padding: 20px 20px;
    border-bottom: 1px solid #B3B3B3;

    .admin-branch-add-branch__title {
      color: #FF662A;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
    }
    .admin-branch-add-branch__close-btn {
      background: url('../images/icon/icon_close.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width:18px;
      height:18px;
      display:block;
      text-indent: -9999px;
      cursor: pointer;
    }
  }

  .admin-branch-add-branch__content-group {
    display:flex;
    width: 100%;
    flex-direction: column;
    padding: 6px 20px 30px 20px;
    background: #fff;
    border-radius: 4px;
    
    .header-group {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 4px;

      .button-group {
        display: flex;
  
        .action-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6px 12px;
          background:#FF662A;
          border-radius: 4px;
          color: #FFf;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          cursor: pointer;
          position: relative;
          padding: 8px 14px;
          box-sizing:border-box;
          margin-right: 8px;
  
          &:last-child {
            margin-right: 0;
          }
  
          &:hover::before {
            content: "";
            position: absolute;
            top:0;
            right:0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.16);
            border-radius: 4px;
            box-sizing:border-box;
          }

          .circular-progress-box {
            width: 119px;
            display: flex;
            align-items: center;
            justify-content: center;
    
            .circular-progress {
              color: #fff;
            }
          }

        }//.action-btn

        .upload-group {
          display: flex;
          border: 1px solid #FF662A;
          justify-content: center;
          align-items: center;
          
          input {
            flex:1;
          }
          .flex{
            flex: 1;
          }
        }


      }
    }
    

    .admin-branch-add-branch__content-content {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 24px;
    }

    .admin-branch-add-branch__input-email {
      display: block;
      width: 100%;
      height: 56px;
      padding: 10px 7px;
      margin-bottom: 30px;
      border: 1px solid #747474;
      border-radius: 4px;
      outline:none;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      box-sizing: border-box;

      &:focus{
        color: #333333;
        border: 2px solid #333333;
        ;
      }

      &::-webkit-input-placeholder { color: #b3b3b3; }
      &:-ms-input-placeholder      { color: #b3b3b3; }
      &::-moz-placeholder          { color: #b3b3b3; }
    }

    .admin-branch-add-branch__content-btn-group{
      display: flex;
      align-items: center;

      .admin-branch-add-branch__content-btn {
        background-color: #FF662A;
        height: 56px;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        display: flex;
        width: 100%;
        padding: 16px;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
  
        &:nth-of-type(1) {
          margin-right: 12px;
          background-color: #747474;
        }
        
        &:hover::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .075);
        }
        &.inactive {
          background-color: #DCDFE2;
        }
      }
    }
  }



  //머티리얼 ui 초기화
  [class^="MuiBox-root MuiBox-root-"],
  [class^="MuiTypography-root MuiTypography-body"] {
    padding: 0;
    margin: 0;
  }

  [class^="PrivateTabIndicator-colorPrimary"] {
    background-color: #fff;
  }


  //머티리얼 탭 UI 수정
  .MuiTabs-root {
      
    .MuiTabs-scroller {
      &.MuiTabs-fixed {

        &.MuiTabs-fixed{

          .PrivateTabIndicator-root-1 {
            &.PrivateTabIndicator-colorPrimary-2 {
              background-color: #fff;
              border-bottom: 4px solid #B3B3B3;
              &.MuiTabs-indicator{
                border-bottom: 4px solid #FF662A;
              }
            }
          }
        }

        .MuiTabs-flexContainer + span {
          background-color: #FF662A;
        }

        .MuiTabs-flexContainer {
          .MuiButtonBase-root {
            &.MuiTab-root {
              
                
              &.MuiTab-textColorInherit { 
                font-size: 18px;
                font-weight: 400;  
                color: #B3B3B3;

                &.Mui-selected {
                  position: relative; 
                  .MuiTab-wrapper {
                    color: #FF662A;  
                    font-weight: 700;     
                  }
                  .MuiTouchRipple-root::before{
                    content: "";
                    display:block;
                    position: absolute;
                    bottom: 0;
                    left: 0;

                  }
                }
              }
            }        
          }
        }
      }
    }
  }
}
