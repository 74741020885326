
//FIND PASSWORD
.find-password__container {
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 378px;
  height:321px;
  background: #fff;
  border-radius: 4px;

  &:focus {
    outline: none;
  }
  
  .find-password__title-group {
    display:flex;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    padding: 20px 20px;
    border-bottom: 1px solid #B3B3B3;

    .find-password__title {
      color: #FF662A;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
    }
    .find-password__close-btn {
      background: url('../images/icon/icon_close.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width:18px;
      height:18px;
      display:block;
      text-indent: -9999px;
      cursor: pointer;
    }
  }

  .find-password__content-group {
    display:flex;
    width: 100%;
    flex-direction: column;
    padding: 30px 20px;
    
    .find-password__content-content {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin: 25px 0;
    }

    .find-password__input-email {
      display: block;
      width: 100%;
      height: 56px;
      padding: 10px 7px;
      margin: 14px 0;
      border: 1px solid #747474;
      border-radius: 4px;
      outline:none;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      box-sizing: border-box;

      &:focus{
        color: #333333;
        border: 2px solid #333333;
        ;
      }

      &::-webkit-input-placeholder { color: #b3b3b3; }
      &:-ms-input-placeholder      { color: #b3b3b3; }
      &::-moz-placeholder          { color: #b3b3b3; }
    }

    .find-password__content-btn {
      background-color: #FF662A;
      height: 56px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      display: flex;
      width: 100%;
      padding: 16px;
      margin: 10px 0;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      cursor: pointer;

      &:hover::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .075);
      }
      &.inactive {
        background-color: #DCDFE2;
      }
    }
  }
}