@import 'fonts';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
input,
select,
textarea,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  overflow-y: auto;
  position: relative;
  min-width: 320px;
  background-color: #fff;
  // background-color: #4e3e9f;
  // font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Open Sans','Noto Sans','Nanum Gothic','Dotum',sans-serif;
  font-family: 'Noto Sans', 'Open Sans', 'Nanum Gothic', 'Dotum', sans-serif !important;
  color: #333;
  font-size: 100%;
  line-height: normal;
  letter-spacing: -0.035em;
  word-spacing: 0.07em;
  font-weight: 400;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;

  &.fixed {
    overflow: hidden;
  }
}
strong {
  font-weight: 700;
}
ul,
ol {
  list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: normal;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img,
fieldset {
  border: 0;
  outline: none;
}
img {
  max-width: 100%;
  vertical-align: top;
  @include transition(opacity 0.5s ease);
}

label {
  vertical-align: middle;
  cursor: pointer;
}
i,
em,
address {
  font-style: normal;
  vertical-align: middle;
}
button {
  margin: 0;
  padding: 0;
  background: none;
  outline: 0;
  font-family: 'Noto Sans', 'Nanum Gothic', '맑은 고딕', '돋움', 'Dotum', sans-serif;
  cursor: pointer;
  border: none;
  line-height: normal;
}
pre {
  padding: 0;
  border: none;
  background: none;
  font-family: 'Noto Sans', 'Nanum Gothic', '맑은 고딕', '돋움', 'Dotum', sans-serif;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
caption,
legend {
  visibility: hidden;
  width: 0px;
  height: 0px;
  font-size: 0px;
  line-height: 0px;
}
iframe,
object,
video,
embed {
  position: relative;
  top: 0;
  left: 0;
  max-width: 100%;
}
.blind {
  display: block;
  overflow: hidden;
  position: absolute;
  top: -1000em;
  left: 0;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

/* html5 ie9 else */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a,
a span {
  text-decoration: none;
  color: #0c0b0b;
  font-size: 1em;
  letter-spacing: -0.055em;
  box-sizing: border-box;
  cursor: pointer;
  @include transition(opacity 0.2s ease);
}
a:visited {
  text-decoration: none;
}
a:hover,
a:hover span {
  text-decoration: none;
}

iframe,
object,
video,
embed {
  position: relative;
  top: 0;
  left: 0;
  max-width: 100%;
}

input,
textarea,
select {
  font-family: 'Noto Sans', 'Nanum Gothic', '맑은 고딕', '돋움', 'Dotum', sans-serif;
  font-size: 1em;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input:focus,
textarea:focus {
  outline: none;
}
textarea {
  resize: none;
  border: none;
  background: none;
}
input[type='text'],
input[type='password'],
input[type='file'] {
  padding: 0;
  margin: 0;
  border: none;
}
input[type='text'],
input[type='image'] {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

select {
  font-size: 0.95em;
  zoom: 1;
}
sup {
  vertical-align: super;
}
textarea {
  display: block;
  width: 100%;
  color: #444444;
  overflow: hidden;
  border: none;
  resize: none;
  background: none;
  overflow-y: auto;
}

/****************************/
/* Common Style             */
/****************************/

/* clear class */
.clear {
  clear: both !important;
}
.clearL {
  clear: left !important;
}
.clfix:after {
  content: '.';
  display: block;
  height: 0px;
  clear: both;
  visibility: hidden;
}
.clfix {
  display: inline-block;
}
.clfix {
  display: block;
}
* html .clfix {
  height: 1%;
} /* hides from ie-mac */
.clfix {
  zoom: 1;
} /*for ie 5.5-7*/

/* ALIGN */
.alC {
  text-align: center !important;
}
.alL {
  text-align: left !important;
}
.alR {
  text-align: right !important;
}
.alJ {
  text-align: justify !important;
}

// @media (max-width: 1400px) {
//   body {
//     font-size: 90%;
//   }
// }
// @media (max-width: 980px) {
// }
// @media (max-width: 680px) {
//   body {
//     font-size: 90%;
//   }
// }
// @media (max-width: 420px) {
//   body {
//     font-size: 80%;
//   }
// }


.clearfix::after {
  content:"";
  clear:both;
  display:block;
}
.float-left {
  float:left;
}
.float-right {
  float:right;
}