.login__container {

  outline: none;



  .login-section {
    height: 100vh;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;



    .login-border-section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;

      .login__close-btn {
        width: 34px;
        height: 34px;
        margin-left: auto;
      }

      .login {
        padding: 35px 47.5px 45px;
        background: #FFFFFF;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        position: relative;

        &.border-radius-bottom {
          border-radius: 4px;
        }

        .login__write_view {
          display: none;

          .logo {
            max-width: 115px;
            height: auto;
          }

          .login__title {
            font-family: "Noto Sans";
            font-size: 24px;
            font-weight: 600;
            color: #000;
            margin-top: 18px;
          }

          .login__subtitle {
            font-family: "Noto Sans";
            font-size: 14px;
            font-weight: 400;
            opacity: 0.9;
            color: #555;
            margin-top: 10px;
            text-align: center;
          }

          .login_term {
            font-weight: 400;
            font-size: 10px;
            text-align: center;
            
            color: rgba(68, 67, 67, 0.55);
            margin-top: 18px;
          }

          &.active {
            display: flex;
            flex-direction: column;
            align-items: center;

            &.isCorp {
              display: block;
            }

            .login__btn {
              font-size: 16px;
              font-weight: 400;
              color: #fff;
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              text-decoration: none;
              box-sizing: border-box;
              border-radius: 4px;
              position: relative;

              background-color: #ff662a;
              height: 56px;
              width: 378px;
              margin: 32px 0;


              &:hover::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .075);
              }

              &.inactive {
                background-color: #DCDFE2;
              }

              >img {
                width: 24px;
                height: 24px;
                margin-left: 17px;
              }

              .empty {
                margin-right: 8px;
                width: 24px;
                height: 24px;
              }

              &.sns__google {
                width: 329px;                margin: 18px 0 0;

                background: #EEECEB;
                border-radius: 8px;

                font-family: 'Noto Sans';
                font-weight: 400;
                font-size: 16px;

                color: #464748;
                padding: 16px 0;

                justify-content: space-between;
              }

              &.sns__naver {
                width: 329px;                margin: 10px 0 0;

                background: rgba(32, 210, 0, 0.7);
                border-radius: 8px;
                font-family: 'Noto Sans';
                font-weight: 400;
                font-size: 16px;

                color: #464748;
                padding: 16px 0;
                justify-content: space-between;
              }


              &.sns__kakao {
                width: 329px;
                margin: 10px 0 0;
                background: rgba(250, 227, 0, 0.7);
                border-radius: 8px;
                font-family: 'Noto Sans';
                font-weight: 400;
                font-size: 16px;

                color: #464748;
                padding: 16px 0;
                justify-content: space-between;
              }
            }

            .login__input {
              margin-top: 30px;

              margin-left: 54.5px;
              margin-right: 54.5px;
              .login__email {
                position: relative;
                margin: 0 0 36px 0;
                width: 378px;

                .login__email-input-box {
                  display: block;
                  width: 100%;
                  height: 56px;
                  padding: 10px 7px;
                  border: 1px solid #747474;
                  border-radius: 4px;
                  outline: none;
                  font-size: 16px;
                  font-weight: 400;
                  color: #333333;
                  box-sizing: border-box;

                  &:focus {
                    color: #333333;
                    border: 2px solid #333333;
                    ;
                  }

                  &:focus+.login__email-title {
                    color: #333333;
                  }
                }

                .login__email-title {
                  font-size: 12px;
                  font-weight: 400;
                  color: #747474;
                  position: absolute;
                  top: -10px;
                  left: 6px;
                  padding: 2px;
                  background: #fff;
                }
              }

              .login__password {
                position: relative;

                .login__password-input-box {
                  display: block;
                  width: 100%;
                  height: 56px;
                  padding: 10px 7px;
                  border: 1px solid #747474;
                  border-radius: 4px;
                  outline: none;
                  font-size: 16px;
                  font-weight: 400;
                  color: #333333;
                  box-sizing: border-box;

                  &:focus {
                    color: #333333;
                    border: 2px solid #333333;
                    ;
                  }

                  &:focus+.login__password-title {
                    color: #333333;
                  }
                }

                .login__password-title {
                  font-size: 12px;
                  font-weight: 400;
                  color: #747474;
                  position: absolute;
                  top: -10px;
                  left: 6px;
                  padding: 2px;
                  background: #fff;
                }
              }


            }

            .login__find-password,
            .login__user-sign-up,
            .login__corp-sign-up {
              width: 378px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              box-sizing: border-box;
              padding-bottom: 21px;

              &.inactive {
                display: none;
              }

              span {
                font-size: 12px;
                font-weight: 400;
                color: #979797;

                &:last-child {
                  cursor: pointer;
                }
              }

              button {
                font-size: 12px;
                color: #000;
                line-height: 1.4;
                text-decoration: underline;

                &:hover {
                  color: rgba(0, 0, 0, .75);
                }
              }


            }
          }
        }

      }

      // input end

      .divider {

        width: 582px;
        height: 8px;
        position: relative;
        background: #FFF;
        background: #DCDFE2;

        &.hide {
          display: none;
        }
      }

      .sns {

        width: 582px;
        padding: 0 102px;
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
        background: #FFF;

        &.hide {
          display: none;
        }

        .sns__title {
          display: flex;
          justify-content: center;
          margin-top: 32px;
          font-size: 14px;
          font-weight: 700;
          color: #333333;
        }

        .sns__button-group {
          display: flex;
          justify-content: center;

          .sns__naver {
            background: url("../images/icon/icon_naver.png");
            background-repeat: no-repeat;
            background-size: cover;
            width: 60px;
            height: 60px;
            display: block;
            text-indent: -9999px;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 20px 16px 60px 0;
          }

          .sns__facebook {
            background: url("../images/icon/icon_facebook.png");
            background-repeat: no-repeat;
            background-size: cover;
            width: 60px;
            height: 60px;
            display: block;
            text-indent: -9999px;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 20px 16px 60px 0;
          }

          .sns__kakao {
            background: url("../images/icon/icon_kakao.png");
            background-repeat: no-repeat;
            background-size: cover;
            width: 60px;
            height: 60px;
            display: block;
            text-indent: -9999px;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 20px 0 60px 0;
          }
        }

        //sns__button-group
      }

      //sns
    }

  }

  // login-section


}

//login__container