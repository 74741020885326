@import 'common';

#root {
  height: 100%;
}

#wrapper {
  position: relative;
  min-height: 100%;

  &::after {
    content: '';
    visibility: hidden;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    @include transition(background-color 0.5s ease);
  }

  &.full-size {
    height: 100%;
    min-height: auto;

    #container {
      height: calc(100% - 234px);
      position: relative;
      z-index: 1;
      top: 0;
      left: 0;
    }
  }

  &.dimmed {
    &::after {
      visibility: visible;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .search-brand {
      position: relative;
      top: 0;
      left: 0;
      z-index: 105;
    }

    .brand-container {
      position: relative;
      padding: 60px 0;

      .search-brand {
        // align-items: start;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 580px;
        box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.16);

        .inner {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: flex-end;
          width: 582px;
          height: 100%;

          .btn-close-search {
            display: inline-block;
          }
        }
      }
    }
  }
}

.section {
  padding: 0 15px;
}

#header {
  padding: 0 15px;
  height: 79px;
  line-height: 79px;
  background-color: #ff662a;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;

  h1.logo {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    // @include elem-pos(0, -50%);
    > a {
      display: block;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 1.15em;
      font-weight: 500;

      .ttoksa {
        background: url('../images/main/logo.png');
        width: 162px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 40%;
        text-indent: -9999px;
        background-size: contain;
        display: block;

        &.other {
          height: 80px;
        }
      }
    }
  }

  #nav {
    margin-left: 190px;
    text-align: left;

    nav {
      overflow: hidden;

      > a {
        position: relative;
        display: block;
        float: left;
        margin-left: 40px;
        color: #fff;
        font-size: 1.18em;

        &:first-child {
          margin-left: 0;
        }

        &.active,
        &:hover {
          color: #ffe951;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            height: 4px;
            background-color: #ffe951;
          }
        }
      }
    }
  }

  .member {
    position: absolute;
    right: 120px;
    top: 50%;
    @include elem-pos(0, -50%);
    display: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: #fff;

    &.on {
      display: block;

      span,
      a {
        color: #fff;
        font-weight: 300;
        &:first-child {
          margin-right: 20px;
        }
      }

      .item-group {
        display: inline-block;

        .username {
          min-width: 110px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          color: #ffffff;

          &:hover {
            color: #ffe951;
          }
          .folding {
            margin-left: 10px;
            width: 22px;
            height: 22px;
            display: inline-block;

          }
        }
      }

      .header__user-info {
        .login-user-name {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;

          &:hover {
            color: #ffe951;
          }
          .folding {
            margin-left: 10px;
            width: 22px;
            height: 22px;
            display: inline-block;
          }
        }
      }
    }
  }
  .corp-member {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    color: #fff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;

    &:hover {
      color: #ffe951;
    }
  }
}

// FILTER STYLE
.filter-container {
  position: relative;
  top: 79px;
  left: 0px;
  z-index: 101;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  @include elem-pos(0, -100%);
  @include transition(transform 0.2s ease);

  &.expanded {
    @include elem-pos(0, 0);
  }

  .sectors-container {
    padding: 20px 0;

    .desc-primary {
      align-items: baseline;

      &:first-child {
        dt {
          min-width: 75px;
        }
      }

      dt {
        min-width: 40px;
      }

      dd {
        .check-outlined {
          margin: 0 4px 4px 0;
          vertical-align: baseline;
        }

        .select {
          margin-right: 4px;
        }
      }
    }
  }

  //필터 접기
  .filter__folding-arrow {
    display: none;
    position: absolute;
    bottom: -24px;
    left: 50%;
    width: 40px;
    height: 24px;
    background: rgb(248, 248, 248);
    border: 1px solid #dcdfe2;
    border-radius: 0 0 4px 4px;
    box-sizing: border-box;
    padding: 10px 4px;
    cursor: pointer;

    &.active {
      display: block;
    }

    .btn--arrow-down {
      background: url('../images/icon/icon_arrow_down.svg');
      background-size: contain;
      background-repeat: no-repeat;
      text-indent: -9999px;
      background-position: 50% -9px;
    }

    .btn--arrow-up {
      background: url('../images/icon/icon_arrow_up.svg');
      background-size: contain;
      background-repeat: no-repeat;
      text-indent: -9999px;
      background-position: 50% -9px;
    }

    &:hover::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.075);
    }
  }
}

// NAVER MAP
.navermap-container {
  position: fixed;
  top: 79px;
  left: 0;
  width: 100%;
  overflow-x: none;
  height: calc(100% - 79px);

  &.expanded {
    top: 203px;
    height: calc(100% - 203px);
  }

  .naver-marker {
    width: 100px;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    @include transition(background-color 0.3s ease-in);

    &-imagebox {
      width: 24px;
      margin: 0 auto;
    }

    &-image {
    }

    &-text {
      font-size: 12px;
    }

    &.checked {
      position: relative;
      background-color: #ffa53e;
      z-index: 5;
    }
  }
  
  //마커 브랜치 리스트 영역
  .marker__branch-list {
    display: none;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.3;
    box-sizing: border-box;
    overflow: auto;
    max-height: 270px;
    padding: 4px 0 4px 0;
    //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

    &.active {
      display: block;

      .inner {
        min-width: 173px;
        display: flex;
        //padding: 12px 20px 9px 13px;
        border-bottom: 1px solid #ecf0f2;
        background: #fff;
        // border-radius: 6px;
        box-sizing: border-box;
        position: relative;
        padding: 4px;
  
        .image__container {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          display: flex;
          align-items: center;
          border-radius: 6px;
          border: 1px solid #dcdfe2;
          
          .image {
            width: 100%;
            text-indent: -9999px;
            display: block;
            border-radius: 6px;
            background-size: cover;
          }
        }
        
  
        .brand-name {
          display: flex;
          flex-direction: column;
          justify-content: center;
  
          span:nth-of-type(1) {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            display: block;
            margin-bottom: 4px;
            min-width: 86px;
          }
  
          span:nth-of-type(2) {
            font-size: 12px;
            font-weight: 400;
            display: block;
            color: #333333;
          }
        }
  
        
  
        .triangle {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 7px 0 7px;
          border-color: #ff662a transparent transparent transparent;
          position: absolute;
          bottom: -10px;
          left: 48%;
  
          &:after {
            content: '';
            position: absolute;
            top: -12px;
            left: -7px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 7px 0 7px;
            border-color: #fff transparent;
          }
        }
      }
    }
  }

  //마커 뱃지 부분
  .map__marker-section {
    position: relative;

    &.highlight {
      .inner {
        // border: 1px solid #979797;
        // background: #b1bac3;
        
        z-index: 100;

        .brand-name {
          :first-child {
            color: #ff662a;}
          
        }
        // .triangle {
        //   width: 0;
        //   height: 0;
        //   border-style: solid;
        //   border-width: 10px 7px 0 7px;
        //   border-color: #979797 transparent transparent transparent;
        //   position: absolute;
        //   bottom: -10px;
        //   left: 48%;

        //   &:after {
        //     content: '';
        //     position: absolute;
        //     top: -12px;
        //     left: -7px;
        //     width: 0;
        //     height: 0;
        //     border-style: solid;
        //     border-width: 10px 7px 0 7px;
        //     border-color: #b1bac3 transparent;
        //   }
        // }
      }
    }

    &.clicked {
      .inner {
        border: 1px solid #eeeeee;
        background: #ff662a;
        color: #fff;
        z-index: 99;

        .brand-name {
          span:nth-of-type(1) {
            color: #fff;
          }

          span:nth-of-type(2) {
            color: #fff;
          }
        }

        .triangle {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 7px 0 7px;
          //border-color: #fff transparent transparent transparent;
          position: absolute;
          bottom: -10px;
          left: 48%;

          &:after {
            content: '';
            position: absolute;
            top: -12px;
            left: -7px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 7px 0 7px;
            border-color: #ff662a transparent;
          }
        }
      }
    }
    
    

    .inner {
      min-width: 76px;
      display: inline-flex;
      padding: 4px 4px 4px 4px;
      border: 1px solid #ff662a;
      background: #fff;
      border-radius: 6px;
      box-sizing: border-box;
      position: relative;

      .image__container {
        width: 30px;
        height: 30px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        border: 1px solid #dcdfe2;

        .image {
          width: 100%;
          text-indent: -9999px;
          display: block;
          border-radius: 6px;
          background-size: cover;
        }
      }
      

      .brand-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: max-content;

        span:nth-of-type(1) {
          font-size: 12px;
          font-weight: 500;
          color: #333333;
          display: block;
          //margin-bottom: 4px;
          // min-width: 86px;

        }

        
        span:nth-of-type(2) {
          font-size: 10px;
          font-weight: 300;
          display: block;
          color: #333333;

        }

      }

      .count-badge {
        display: none;
        position: absolute;
        top: -8px;
        right: -12px;
        background: #ff662a;
        color: #fff;
        font-size: 12px;
        font-weight: 300;
        border-radius: 10px;
        line-height: 1.3;
        padding: 1px 4px 1px 4px;
        box-sizing: border-box;
        z-index: 101;
        
        &.active {
          display: block;
        }
      }

      .triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 7px 0 7px;
        border-color: #ff662a transparent transparent transparent;
        position: absolute;
        bottom: -10px;
        left: 48%;

        &:after {
          content: '';
          position: absolute;
          top: -12px;
          left: -7px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 7px 0 7px;
          border-color: #fff transparent;
        }
      }
    }

    &.bigger {
      .inner {
        min-width: 173;
        display: inline-flex;
        padding: 12px 20px 9px 13px;
        border: 1px solid #ff662a;
        background: #fff;
        border-radius: 6px;
        box-sizing: border-box;
        position: relative;
  
        .image__container {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          display: flex;
          align-items: center;
          border-radius: 6px;
          border: 1px solid #dcdfe2;
          
          .image {
            width: 100%;
            text-indent: -9999px;
            display: block;
            border-radius: 6px;
            background-size: cover;
          }
        }
        
  
        .brand-name {
          display: flex;
          flex-direction: column;
          justify-content: center;
          
          span:nth-of-type(1) {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            display: block;
            margin-bottom: 4px;
            min-width: 86px;
          }
  
          
          span:nth-of-type(2) {
            font-size: 12px;
            font-weight: 400;
            display: block;
            color: #333333;
          }
  
        }
  
        .count-badge {
          display: none;
          position: absolute;
          top: -8px;
          right: -12px;
          background: #ff662a;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          border-radius: 10px;
          line-height: 1.3;
          padding: 1px 4px 1px 4px;
          box-sizing: border-box;
          z-index: 101;        
  
          &.active {
            display: block;
          }
        }
  
        .triangle {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 7px 0 7px;
          border-color: #ff662a transparent transparent transparent;
          position: absolute;
          bottom: -10px;
          left: 48%;
  
          &:after {
            content: '';
            position: absolute;
            top: -12px;
            left: -7px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 7px 0 7px;
            border-color: #fff transparent;
          }
        }
      }
    }
  }
}

.marker__flag {
  position: fixed;
  right: 19px;
  bottom: 177px;
  z-index: 103;
  .btn-color {
    background-color: #ff662a;
    color:#fff;
    border-radius: 20px;

    &.on {
      color:#333333;
    }
  }
}

#aside {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  @include elem-pos(-100%, 0);
  @include transition(transform 0.2s ease);

  &.active {
    @include elem-pos(0, 0);

    .mapAsideContainer-2dep.active {
      flex: 1;
      min-width: 378px;
    }

    .mapAsideContainer-3dep.active {
      flex: 1;
      min-width: 378px;
      overflow: visible;
    }
  }
}

.inner {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
}

.brandDetailParent {
  display: flex;
  height: 100%;
}

.brandDetailLeft {
  width: 70%;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
}

.brandDetailRight {
  width: 30%;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[class^="jss"] {
  padding: 0;
  margin: 0;
}