.camparison-popup__container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  transition: all 3s;
  max-height: 100%;
  overflow-y: auto;

  &:focus {
    outline: none;
  }

  .camparison-popup__section {
    display: flex;
    width: 1280px;
    margin: 0 auto;

    //왼쪽 영역
    .camparison-popup__left {
      width: 480px;
      padding: 50px 66px 0 0;
      box-sizing: border-box;
      overflow: auto;

      .camparison-popup__left-title-group {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .camparison-popup__left-title {
          color: #333333;
          font-size: 24px;
          font-weight: 700;
          display: block;
          margin-right: 8px;
        }
        .camparison-popup__count {
          color: #ff662a;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .divider {
        border-bottom: 2px solid #333333;
        margin-bottom: 20px;
      }

      .camparison-popup__list-item {
        display: flex;
        position: relative;
        align-items: center;
        box-sizing: border-box;
        padding: 8px 0;
        margin-bottom: 20px;

        .camparison-popup__brand-img__container {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          border-radius: 6px;
          border: 1px solid #dcdfe2;

          .camparison-popup__brand_img {
            width: 100%;
            //background-image: url("../images/common/img_logo_sample.jpg");
            background-position: 50% 50%;
            background-repeat: no-repeat;
            text-indent: -9999px;
          }
        }
        

        .camparison-popup__item-right {
          flex: 1;

          .camparison-popup__info-and-event {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .camparison-popup__brand_info {
              span {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                line-height: 1.3;
                display: block;
                margin-right: 4px;
              }

              .camparison-popup__brand_category {
                color: #ff662a;
                display: block;
                margin-right: 8px;
              }

              .camparison-popup__brand-name {
                display: block;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.4;
                color: #000000;
              }
            }

            .camparison-popup__right-group {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;

              .util {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .hart {
                  display: flex;

                  .favorite {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border: 0;
                    background-image: url('../images/icon/icon_like_default.svg');
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 20px;

                    &.on {
                      display: inline-block;
                      border: 0;
                      background-image: url('../images/icon/icon_like_select.svg');
                    }
                  }
                }
              }

              .close-btn {
                background: url('../images/icon/icon_close.svg');
                background-repeat: no-repeat;
                background-size: cover;
                width: 12px;
                height: 12px;
                display: block;
                text-indent: -9999px;
                cursor: pointer;
              }

              .camparison-popup__brand-intro-ask {
                display: flex;
                justify-content: flex-end;
                align-items: center;
              }
            }
          }
        }
      }

      .camparison-popup__brand-intro-ask {
        display: flex;
        margin-left: 60px;
        margin-top: 8px;

        a {
          font-size: 12px;

          .camparison-popup__brand-intro {
            font-size: 12px;
            color: #333333;
            text-decoration: underline;
            box-sizing: border-box;
            margin-right: 20px;
            cursor: pointer;
          }
        }

        .camparison-popup__brand-ask {
          font-size: 12px;
          color: #333333;
          text-decoration: underline;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }

    //오른쪽 영역
    .camparison-popup__right {
      width: 800px;
      background: #dcdfe2;
      padding: 50px 125px 74px 96px;
      box-sizing: border-box;
      overflow: auto;

      .camparison-popup_right-inner {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 20px;

        .title {
          color: #ff662a;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.4;
        }
        .close-btn {
          background: url('../images/icon/icon_close.svg');
          background-repeat: no-repeat;
          background-size: cover;
          width: 18px;
          height: 18px;
          display: block;
          text-indent: -9999px;
          cursor: pointer;
        }
      }

      .chart-group {
        span {
          color: #333333;
          font-size: 16px;
          font-weight: 700;
          display: block;
          margin-bottom: 20px;
        }
        .chart {
          margin-bottom: 60px;
        }
      }
    }
  }
}
