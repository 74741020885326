.no-match__container {
  $a: 100vh;
  $b: 79px;
  height: calc(#{$a} - #{$b});
  position: relative;

  .no-match__section {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 438px;
    height: 402px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-match__alram {
      background: url('../images/icon/icon_warning.png');
      background-repeat: no-repeat;
      background-size: 100px 100px;
      display: block;
      width: 100px;
      height: 100px;
      text-indent: -9999px;
      margin-bottom: 60px;
    }
    .no-match__title {
      color: #333333;
      font-size: 44px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .no-match__content {
      text-align: center;
      color: #333333;
      font-size: 16px;
      font-weight: 400;
    }
    .no-match__go-home {
      background-color: #ff662a;
      width: 156px;
      height: 56px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      display: flex;
      padding: 16px;
      margin: 40px 0 0;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;

      &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.075);
      }
      &.inactive {
        background-color: #dcdfe2;
      }
    }
  }
}
