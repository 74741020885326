 .super-admin__excel-bulk-upload__container {
   height: 100vh;
   margin: auto;
   display:flex;
   justify-content: center;
   align-items: center;

   .title {
    font-size: 20px;
    font-weight: 700;
   }
   input {
     width: 400px;
     height: 60px;
   }
   div {
     margin: 60px 0;
   }
   .file-upload-btn{
     width: 400px;
     height: 60px;
     color: #fff;
     font-size: 16px;
     font-weight: 400;
     border: 1px solid #eee;
     border-radius: 4px;
     background: #FF662A;
     box-sizing: border-box;
   }
 }