.join-complete__container {
  position:relative;
  
  .header-section{
    background: #FF662A ;
    position:relative;
    margin-bottom: 100px;

    &::before {
      content:"";
      display:block;
      position:absolute;
      background: url("../images/icon/icon_complete_header.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 9%;
      opacity: 0.21;
      top:0;
      right:0;
      left:0;
      bottom:0;
      width:100%;
      height:100%;
    }

    .inner {
      width: 1280px;
      margin: 0 auto;
    
      .gradient{
        padding: 60px 0;
        box-sizing: border-box;
        
    
        .title{
          display:block;
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          margin-bottom:20px;
        }
        .sub-title {
          display:block;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
    
        }
  
      }
    }
  }  
}